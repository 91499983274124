export default `
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
  <mask id="mask0_1481_29258" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="20">
    <rect x="0.5" width="20" height="20" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_1481_29258)">
    <path d="M6.33301 18.3333C5.87467 18.3333 5.48231 18.1701 5.15592 17.8437C4.82954 17.5173 4.66634 17.125 4.66634 16.6666C4.66634 16.2083 4.82954 15.8159 5.15592 15.4895C5.48231 15.1632 5.87467 15 6.33301 15C6.79134 15 7.1837 15.1632 7.51009 15.4895C7.83648 15.8159 7.99967 16.2083 7.99967 16.6666C7.99967 17.125 7.83648 17.5173 7.51009 17.8437C7.1837 18.1701 6.79134 18.3333 6.33301 18.3333ZM14.6663 18.3333C14.208 18.3333 13.8156 18.1701 13.4893 17.8437C13.1629 17.5173 12.9997 17.125 12.9997 16.6666C12.9997 16.2083 13.1629 15.8159 13.4893 15.4895C13.8156 15.1632 14.208 15 14.6663 15C15.1247 15 15.517 15.1632 15.8434 15.4895C16.1698 15.8159 16.333 16.2083 16.333 16.6666C16.333 17.125 16.1698 17.5173 15.8434 17.8437C15.517 18.1701 15.1247 18.3333 14.6663 18.3333ZM5.62467 4.99996L7.62467 9.16663H13.458L15.7497 4.99996H5.62467ZM4.83301 3.33329H17.1247C17.4441 3.33329 17.6872 3.47565 17.8538 3.76038C18.0205 4.0451 18.0275 4.33329 17.8747 4.62496L14.9163 9.95829C14.7636 10.2361 14.5587 10.4513 14.3018 10.6041C14.0448 10.7569 13.7636 10.8333 13.458 10.8333H7.24967L6.33301 12.5H16.333V14.1666H6.33301C5.70801 14.1666 5.23579 13.8923 4.91634 13.3437C4.5969 12.7951 4.58301 12.25 4.87467 11.7083L5.99967 9.66663L2.99967 3.33329H1.33301V1.66663H4.04134L4.83301 3.33329Z" fill="white"/>
  </g>
</svg>
`
