export default `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <mask id="mask0_857_53479" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
    <rect width="24" height="24" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_857_53479)">
    <path d="M7 2H17L15 9H19L16.075 13.225L14.65 11.8L15.2 11H13.85L12.675 9.825L14.35 4H9V6.15L7 4.15V2ZM10 22V14H7V9.85L1.375 4.225L2.8 2.8L21.2 21.2L19.775 22.625L13.75 16.6L10 22Z" fill="black"/>
  </g>
</svg>
`
