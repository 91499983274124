export default `
<svg xmlns="http://www.w3.org/2000/svg" width="51" height="50" viewBox="0 0 51 50" fill="none">
  <g clip-path="url(#clip0_4616_123666)">
    <path d="M24.8489 50.4545C37.4008 50.4545 47.5761 40.2792 47.5761 27.7273C47.5761 15.1753 37.4008 5 24.8489 5C12.2969 5 2.12158 15.1753 2.12158 27.7273C2.12158 40.2792 12.2969 50.4545 24.8489 50.4545Z" fill="url(#paint0_radial_4616_123666)"/>
    <path d="M48.0304 30.4545C48.03 30.8295 48.1455 31.1955 48.3612 31.5022C48.5768 31.809 48.882 32.0416 49.235 32.1682C49.41 32.2233 49.5633 32.3318 49.6734 32.4786C49.7834 32.6254 49.8447 32.8029 49.8486 32.9864V40C49.8486 40.2411 49.7528 40.4723 49.5824 40.6428C49.4119 40.8133 49.1806 40.9091 48.9395 40.9091H38.7441C38.5607 40.9052 38.3831 40.8439 38.2363 40.7338C38.0896 40.6238 37.981 40.4704 37.9259 40.2955C37.8003 39.9414 37.5682 39.6348 37.2613 39.4181C36.9544 39.2013 36.588 39.0849 36.2123 39.0849C35.8366 39.0849 35.4701 39.2013 35.1632 39.4181C34.8564 39.6348 34.6242 39.9414 34.4986 40.2955C34.4435 40.4704 34.335 40.6238 34.1882 40.7338C34.0414 40.8439 33.8639 40.9052 33.6804 40.9091H10.7577C10.5166 40.9091 10.2854 40.8133 10.1149 40.6428C9.94441 40.4723 9.84863 40.2411 9.84863 40V20H34.3941C34.3941 20.4822 34.5856 20.9447 34.9266 21.2856C35.2676 21.6266 35.7301 21.8182 36.2123 21.8182C36.6945 21.8182 37.1569 21.6266 37.4979 21.2856C37.8389 20.9447 38.0304 20.4822 38.0304 20H48.9395C49.1806 20 49.4119 20.0958 49.5824 20.2663C49.7528 20.4368 49.8486 20.668 49.8486 20.9091V27.9227C49.8447 28.1061 49.7834 28.2837 49.6734 28.4305C49.5633 28.5773 49.41 28.6858 49.235 28.7409C48.882 28.8675 48.5768 29.1001 48.3612 29.4068C48.1455 29.7136 48.03 30.0796 48.0304 30.4545Z" fill="#0055A1"/>
    <path d="M40.3035 25C40.3031 25.3749 40.4186 25.7409 40.6342 26.0477C40.8499 26.3544 41.1551 26.587 41.508 26.7136C41.683 26.7687 41.8364 26.8772 41.9464 27.024C42.0565 27.1708 42.1178 27.3484 42.1217 27.5318V34.5454C42.1217 34.7865 42.0259 35.0177 41.8554 35.1882C41.6849 35.3587 41.4537 35.4545 41.2126 35.4545H31.0171C30.8337 35.4506 30.6561 35.3893 30.5094 35.2792C30.3626 35.1692 30.254 35.0159 30.199 34.8409C30.0734 34.4868 29.8412 34.1803 29.5344 33.9635C29.2275 33.7467 28.861 33.6303 28.4853 33.6303C28.1096 33.6303 27.7431 33.7467 27.4363 33.9635C27.1294 34.1803 26.8972 34.4868 26.7717 34.8409C26.7166 35.0159 26.608 35.1692 26.4613 35.2792C26.3145 35.3893 26.1369 35.4506 25.9535 35.4545H3.48532C2.23623 31.9975 1.84425 28.2891 2.34301 24.6474C2.84177 21.0057 4.21638 17.5393 6.34896 14.5454H25.9535C26.1369 14.5493 26.3145 14.6106 26.4613 14.7207C26.608 14.8307 26.7166 14.9841 26.7717 15.159C26.8972 15.5131 27.1294 15.8197 27.4363 16.0364C27.7431 16.2532 28.1096 16.3696 28.4853 16.3696C28.861 16.3696 29.2275 16.2532 29.5344 16.0364C29.8412 15.8197 30.0734 15.5131 30.199 15.159C30.254 14.9841 30.3626 14.8307 30.5094 14.7207C30.6561 14.6106 30.8337 14.5493 31.0171 14.5454H41.2126C41.4537 14.5454 41.6849 14.6412 41.8554 14.8117C42.0259 14.9822 42.1217 15.2134 42.1217 15.4545V22.4681C42.1178 22.6516 42.0565 22.8291 41.9464 22.9759C41.8364 23.1227 41.683 23.2312 41.508 23.2863C41.1551 23.4129 40.8499 23.6455 40.6342 23.9523C40.4186 24.259 40.3031 24.625 40.3035 25Z" fill="#FF005B"/>
    <path d="M28.9399 19.091C28.9399 18.84 28.7363 18.6365 28.4853 18.6365C28.2343 18.6365 28.0308 18.84 28.0308 19.091V20.0001C28.0308 20.2511 28.2343 20.4547 28.4853 20.4547C28.7363 20.4547 28.9399 20.2511 28.9399 20.0001V19.091Z" fill="#8FA2FF"/>
    <path d="M28.9399 22.7273C28.9399 22.4762 28.7363 22.2727 28.4853 22.2727C28.2343 22.2727 28.0308 22.4762 28.0308 22.7273V23.6363C28.0308 23.8874 28.2343 24.0909 28.4853 24.0909C28.7363 24.0909 28.9399 23.8874 28.9399 23.6363V22.7273Z" fill="#8FA2FF"/>
    <path d="M28.9399 26.3637C28.9399 26.1127 28.7363 25.9092 28.4853 25.9092C28.2343 25.9092 28.0308 26.1127 28.0308 26.3637V27.2728C28.0308 27.5239 28.2343 27.7274 28.4853 27.7274C28.7363 27.7274 28.9399 27.5239 28.9399 27.2728V26.3637Z" fill="#8FA2FF"/>
    <path d="M28.9399 30C28.9399 29.7489 28.7363 29.5454 28.4853 29.5454C28.2343 29.5454 28.0308 29.7489 28.0308 30V30.909C28.0308 31.1601 28.2343 31.3636 28.4853 31.3636C28.7363 31.3636 28.9399 31.1601 28.9399 30.909V30Z" fill="#8FA2FF"/>
    <path d="M31.6667 21.3635H38.0303C38.2814 21.3635 38.4849 21.16 38.4849 20.909C38.4849 20.6579 38.2814 20.4544 38.0303 20.4544H31.6667C31.4156 20.4544 31.2121 20.6579 31.2121 20.909C31.2121 21.16 31.4156 21.3635 31.6667 21.3635Z" fill="white"/>
    <path d="M31.6667 24.0908H38.0303C38.2814 24.0908 38.4849 23.8873 38.4849 23.6363C38.4849 23.3852 38.2814 23.1817 38.0303 23.1817H31.6667C31.4156 23.1817 31.2121 23.3852 31.2121 23.6363C31.2121 23.8873 31.4156 24.0908 31.6667 24.0908Z" fill="white"/>
    <path d="M31.6667 26.8181H38.0303C38.2814 26.8181 38.4849 26.6146 38.4849 26.3636C38.4849 26.1125 38.2814 25.909 38.0303 25.909H31.6667C31.4156 25.909 31.2121 26.1125 31.2121 26.3636C31.2121 26.6146 31.4156 26.8181 31.6667 26.8181Z" fill="white"/>
    <path d="M5.30296 33.6362H7.12114C7.37218 33.6362 7.57568 33.4327 7.57568 33.1817C7.57568 32.9306 7.37218 32.7271 7.12114 32.7271H5.30296C5.05192 32.7271 4.84841 32.9306 4.84841 33.1817C4.84841 33.4327 5.05192 33.6362 5.30296 33.6362Z" fill="white"/>
    <path d="M31.6667 29.5454H38.0303C38.2814 29.5454 38.4849 29.3419 38.4849 29.0909C38.4849 28.8398 38.2814 28.6363 38.0303 28.6363H31.6667C31.4156 28.6363 31.2121 28.8398 31.2121 29.0909C31.2121 29.3419 31.4156 29.5454 31.6667 29.5454Z" fill="white"/>
    <path d="M9.84863 30.4545C9.66939 30.4537 9.49437 30.4 9.34557 30.3001C9.19677 30.2001 9.08084 30.0584 9.01235 29.8928C8.94385 29.7271 8.92586 29.5449 8.96062 29.3691C8.99538 29.1932 9.08135 29.0316 9.20773 28.9045L18.2986 19.8136C18.4725 19.6646 18.6962 19.5868 18.925 19.5956C19.1538 19.6045 19.3709 19.6993 19.5328 19.8612C19.6947 20.0231 19.7895 20.2402 19.7984 20.469C19.8072 20.6978 19.7294 20.9215 19.5805 21.0954L10.4895 30.1863C10.3198 30.357 10.0894 30.4535 9.84863 30.4545Z" fill="white"/>
    <path d="M10.303 24.5454C9.76355 24.5454 9.23626 24.3854 8.78777 24.0857C8.33927 23.7861 7.98971 23.3601 7.78329 22.8618C7.57687 22.3634 7.52286 21.8151 7.62809 21.286C7.73332 20.757 7.99307 20.271 8.37448 19.8896C8.7559 19.5082 9.24185 19.2485 9.77089 19.1432C10.2999 19.038 10.8483 19.092 11.3466 19.2984C11.845 19.5048 12.2709 19.8544 12.5706 20.3029C12.8703 20.7514 13.0302 21.2787 13.0302 21.8181C13.0302 22.5414 12.7429 23.2351 12.2314 23.7466C11.72 24.258 11.0263 24.5454 10.303 24.5454ZM10.303 20.909C10.1232 20.909 9.94739 20.9623 9.79789 21.0622C9.64839 21.1621 9.53187 21.3041 9.46307 21.4702C9.39426 21.6363 9.37626 21.8191 9.41133 21.9954C9.44641 22.1718 9.53299 22.3338 9.66013 22.4609C9.78727 22.5881 9.94926 22.6746 10.1256 22.7097C10.3019 22.7448 10.4847 22.7268 10.6509 22.658C10.817 22.5892 10.9589 22.4727 11.0588 22.3232C11.1587 22.1737 11.212 21.9979 11.212 21.8181C11.212 21.577 11.1163 21.3458 10.9458 21.1753C10.7753 21.0048 10.5441 20.909 10.303 20.909Z" fill="white"/>
    <path d="M19.3943 30.9089C18.8549 30.9089 18.3276 30.7489 17.8791 30.4493C17.4306 30.1496 17.081 29.7236 16.8746 29.2253C16.6682 28.727 16.6142 28.1786 16.7194 27.6496C16.8246 27.1205 17.0844 26.6346 17.4658 26.2531C17.8472 25.8717 18.3332 25.612 18.8622 25.5068C19.3912 25.4015 19.9396 25.4555 20.4379 25.6619C20.9363 25.8684 21.3622 26.2179 21.6619 26.6664C21.9616 27.1149 22.1215 27.6422 22.1215 28.1816C22.1215 28.9049 21.8342 29.5986 21.3227 30.1101C20.8113 30.6216 20.1176 30.9089 19.3943 30.9089ZM19.3943 27.2725C19.2145 27.2725 19.0387 27.3258 18.8892 27.4257C18.7397 27.5256 18.6232 27.6676 18.5544 27.8337C18.4856 27.9998 18.4676 28.1826 18.5026 28.359C18.5377 28.5353 18.6243 28.6973 18.7514 28.8244C18.8786 28.9516 19.0406 29.0382 19.2169 29.0732C19.3933 29.1083 19.576 29.0903 19.7422 29.0215C19.9083 28.9527 20.0503 28.8362 20.1501 28.6867C20.25 28.5372 20.3034 28.3614 20.3034 28.1816C20.3034 27.9405 20.2076 27.7093 20.0371 27.5388C19.8666 27.3683 19.6354 27.2725 19.3943 27.2725Z" fill="white"/>
  </g>
  <defs>
    <radialGradient id="paint0_radial_4616_123666" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24.8489 27.7273) rotate(90) scale(22.7273)">
      <stop offset="0.545" stop-color="white"/>
      <stop offset="1" stop-color="#FFFCAA"/>
    </radialGradient>
    <clipPath id="clip0_4616_123666">
      <rect width="50" height="50" fill="white" transform="translate(0.666992)"/>
    </clipPath>
  </defs>
</svg>
`
