export default `
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="close_small">
    <mask id="mask0_2036_15478" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
      <rect id="Bounding box" width="20" height="20" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_2036_15478)">
      <path id="close_small_2"
            d="M5.68421 16L4 14.3158L8.33083 9.98496L4 5.68421L5.68421 4L10.015 8.33083L14.3158 4L16 5.68421L11.6692 9.98496L16 14.3158L14.3158 16L10.015 11.6692L5.68421 16Z"
            fill="#212529" />
    </g>
  </g>
</svg>
`
