export default `
<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="17.8187" cy="18.2" r="5.2" fill="#CCDDEC" />
  <ellipse cx="20.6187" cy="4.19999" rx="2.4" ry="2.4" fill="#FFE066" />
  <ellipse cx="8.61875" cy="13.8" rx="2.4" ry="2.4" fill="#F499B9" />
  <path
    d="M16.8083 6.88964L16.6666 4.97116C16.5071 2.85729 14.7357 1.1875 12.61 1.1875C10.4843 1.1875 8.69514 2.85729 8.55343 4.97116L8.41171 6.88964H6.37457C5.542 6.88964 4.85115 7.5469 4.78029 8.38179L3.82372 21.8644C3.78829 22.3085 3.94772 22.7526 4.24886 23.0724C4.55 23.3921 4.97515 23.5875 5.418 23.5875H19.8197C20.2625 23.5875 20.6877 23.4099 20.9888 23.0724C21.29 22.7526 21.4494 22.3085 21.414 21.8644L20.4574 8.50614C20.3865 7.60019 19.6425 6.90741 18.7391 6.90741H16.8083V6.88964ZM9.61628 5.04222C9.72257 3.47901 11.0334 2.25332 12.61 2.25332C14.1866 2.25332 15.4974 3.47901 15.6037 5.04222L15.7277 6.88964H9.47457L9.61628 5.04222ZM19.3945 8.57719L20.3511 21.9355C20.3688 22.0776 20.3157 22.2197 20.2094 22.344C20.1031 22.4506 19.9614 22.5217 19.8197 22.5217H5.418C5.27629 22.5217 5.13457 22.4684 5.02829 22.344C4.922 22.2197 4.86886 22.0954 4.88657 21.9355L5.84314 8.45285C5.86086 8.16863 6.09114 7.95546 6.37457 7.95546H8.34085L8.23457 9.37656C8.21685 9.67854 8.42943 9.92723 8.73057 9.94499H8.766C9.04942 9.94499 9.27971 9.73183 9.29742 9.44761L9.40371 7.95546H15.8163L15.9226 9.44761C15.9403 9.74959 16.1883 9.96276 16.4894 9.94499C16.7906 9.92723 17.0031 9.67854 16.9854 9.37656L16.8791 7.95546H18.7391C19.0757 7.95546 19.3591 8.22192 19.3945 8.57719Z"
    fill="#E40051" stroke="#E40051" stroke-width="0.5" />
</svg>
`
