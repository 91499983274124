import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  input,
  NgModule,
  numberAttribute,
} from '@angular/core'
import { IconList } from '../icon/icon-list'

@Component({
  selector: 'app-icon',
  template: '',
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  public name = input.required<IconName>()
  public size = input(16, {
    transform: numberAttribute,
  })
  public ignoreBaseline = input(false, {
    transform: booleanAttribute,
  })

  constructor(public element: ElementRef) {
    this.handleNameChange()
    this.handleIgnoreBaselineChange()
  }

  private handleNameChange() {
    effect(() => {
      const iconSvg = IconList[this.name()]
      this.element.nativeElement.innerHTML = '' // Reset icon
      if (iconSvg) {
        this.element.nativeElement.innerHTML = `<i class="anticon">${iconSvg}</i>`
        // Add necessary style, since css class wont work when injecting html dynamically (css encapsulation?)
        const iElement = this.element.nativeElement.children[0]
        // Drag the icon down to baseline
        iElement.style.top = '.05em'
        iElement.style.position = 'relative'
        const svgElement = iElement.children[0]
        // This allows the icon to change its size based on the text to it, or <i> fontsize
        svgElement.style.width = '1em'
        svgElement.style.height = '1em'

        this.setIconFontSize()
      }
    })
  }

  private handleIgnoreBaselineChange(): void {
    effect(() => {
      if (this.element.nativeElement.children[0] && this.ignoreBaseline()) {
        this.element.nativeElement.children[0].style.top = `0`
      }
    })
  }

  private setIconFontSize(): void {
    if (!this.element.nativeElement.children[0]) {
      return
    }
    this.element.nativeElement.children[0].style.fontSize = `${this.size()}px`
  }
}

@NgModule({
  declarations: [IconComponent],
  imports: [],
  exports: [IconComponent],
})
export class IconModule {}

export type IconName = keyof typeof IconList
