export default `
<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
  <mask id="mask0_4754_109630" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="17">
    <rect x="0.5" y="0.5" width="16" height="16" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_4754_109630)">
    <path d="M4.92238 12.5001L3.8335 11.4911L8.50016 7.16675L13.1668 11.4911L12.0779 12.5001L8.50016 9.20278L4.92238 12.5001Z" fill="#0055A1"/>
  </g>
</svg>
`
