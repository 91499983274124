export default `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <mask id="mask0_869_54610" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
    <rect width="24" height="24" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_869_54610)">
    <path d="M12 18C13.3167 18 14.45 17.5542 15.4 16.6625C16.35 15.7708 16.8667 14.6667 16.95 13.35L17.7 14.05L18.75 13L16.25 10.5L13.75 13L14.8 14.05L15.45 13.4C15.35 14.2833 14.975 15.0208 14.325 15.6125C13.675 16.2042 12.9 16.5 12 16.5C11.7833 16.5 11.5708 16.4792 11.3625 16.4375C11.1542 16.3958 10.95 16.3333 10.75 16.25L9.65 17.35C10.0167 17.55 10.3958 17.7083 10.7875 17.825C11.1792 17.9417 11.5833 18 12 18ZM7.75 15.5L10.25 13L9.2 11.95L8.55 12.6C8.65 11.7167 9.025 10.9792 9.675 10.3875C10.325 9.79583 11.1 9.5 12 9.5C12.2167 9.5 12.4292 9.52083 12.6375 9.5625C12.8458 9.60417 13.05 9.66667 13.25 9.75L14.35 8.65C13.9833 8.45 13.6042 8.29167 13.2125 8.175C12.8208 8.05833 12.4167 8 12 8C10.6833 8 9.55 8.44583 8.6 9.3375C7.65 10.2292 7.13333 11.3333 7.05 12.65L6.3 11.95L5.25 13L7.75 15.5ZM4 21C3.45 21 2.97917 20.8042 2.5875 20.4125C2.19583 20.0208 2 19.55 2 19V7C2 6.45 2.19583 5.97917 2.5875 5.5875C2.97917 5.19583 3.45 5 4 5H7.15L9 3H15L16.85 5H20C20.55 5 21.0208 5.19583 21.4125 5.5875C21.8042 5.97917 22 6.45 22 7V19C22 19.55 21.8042 20.0208 21.4125 20.4125C21.0208 20.8042 20.55 21 20 21H4ZM4 19H20V7H15.95L14.125 5H9.875L8.05 7H4V19Z" fill="#1C1B1F"/>
  </g>
</svg>
`
