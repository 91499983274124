export default `
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
  <mask id="mask0_4616_123568" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
    <rect width="30" height="30" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_4616_123568)">
    <path d="M5 6.25V3.75H25V6.25H5ZM13.75 26.25V13.5L10.5 16.75L8.75 15L15 8.75L21.25 15L19.5 16.75L16.25 13.5V26.25H13.75Z" fill="#0055A1"/>
  </g>
</svg>
`
