export default `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <mask id="mask0_160_4518" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
    <rect width="24" height="24" fill="#D9D9D9" />
  </mask>
  <g mask="url(#mask0_160_4518)">
    <path d="M14 22L24 12L14 2L12.225 3.775L20.45 12L12.225 20.225L14 22Z" fill="#0055A1" />
  </g>
</svg>
`
