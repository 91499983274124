export default `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <mask id="mask0_1861_20255" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
    <rect width="24" height="24" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_1861_20255)">
    <circle cx="12" cy="12" r="10" fill="#E40051"/>
    <path d="M11.1429 12.8571H6V11.1429H11.1429V6H12.8571V11.1429H18V12.8571H12.8571V18H11.1429V12.8571Z" fill="white"/>
  </g>
</svg>
`
