export default `
<svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
  <mask id="mask0_1443_40051" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="13">
    <rect y="0.5" width="12" height="12" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_1443_40051)">
    <path d="M3.5 11C3.225 11 2.98958 10.9021 2.79375 10.7063C2.59792 10.5104 2.5 10.275 2.5 10V3.5H2V2.5H4.5V2H7.5V2.5H10V3.5H9.5V10C9.5 10.275 9.40208 10.5104 9.20625 10.7063C9.01042 10.9021 8.775 11 8.5 11H3.5ZM8.5 3.5H3.5V10H8.5V3.5ZM4.5 9H5.5V4.5H4.5V9ZM6.5 9H7.5V4.5H6.5V9Z" fill="#0055A1"/>
  </g>
</svg>
`
