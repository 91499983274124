export default `
<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
  <g clip-path="url(#clip0_4616_123689)">
    <path d="M23.1819 49.5456C35.7338 49.5456 45.9091 39.3703 45.9091 26.8183C45.9091 14.2664 35.7338 4.09106 23.1819 4.09106C10.6299 4.09106 0.45459 14.2664 0.45459 26.8183C0.45459 39.3703 10.6299 49.5456 23.1819 49.5456Z" fill="url(#paint0_radial_4616_123689)"/>
    <path d="M39.5456 24.091V42.582C37.4255 44.7815 34.8838 46.5311 32.0722 47.7262C29.2606 48.9212 26.237 49.5371 23.182 49.5371C20.127 49.5371 17.1034 48.9212 14.2918 47.7262C11.4802 46.5311 8.93844 44.7815 6.81836 42.582V24.091C6.81836 23.3677 7.1057 22.674 7.61716 22.1626C8.12862 21.6511 8.82231 21.3638 9.54563 21.3638H36.8184C37.5417 21.3638 38.2354 21.6511 38.7468 22.1626C39.2583 22.674 39.5456 23.3677 39.5456 24.091Z" fill="#0055A1"/>
    <path d="M28.1816 21.3638V30.9092C28.1815 30.9898 28.16 31.0688 28.1192 31.1383C28.0785 31.2078 28.02 31.2652 27.9498 31.3047C27.8819 31.3432 27.8052 31.3635 27.7271 31.3638C27.6455 31.3637 27.5654 31.3418 27.4953 31.3001L25.4544 30.0774L23.4135 31.3001C23.3444 31.34 23.266 31.361 23.1862 31.361C23.1064 31.361 23.028 31.34 22.9589 31.3001L20.9089 30.0774L18.868 31.3001C18.799 31.341 18.7204 31.363 18.6402 31.3637C18.5599 31.3644 18.4809 31.3438 18.4112 31.3041C18.3415 31.2644 18.2836 31.207 18.2432 31.1376C18.2029 31.0682 18.1817 30.9895 18.1816 30.9092V21.3638H28.1816Z" fill="#99BBD9"/>
    <path d="M19.5454 44.091V47.7273C19.5454 47.9684 19.4496 48.1997 19.2791 48.3702C19.1087 48.5407 18.8774 48.6364 18.6363 48.6364H16.8181C14.1934 47.8755 11.729 46.6433 9.54541 45.0001V44.091C9.54541 43.8499 9.64119 43.6186 9.81168 43.4482C9.98216 43.2777 10.2134 43.1819 10.4545 43.1819H18.6363C18.8774 43.1819 19.1087 43.2777 19.2791 43.4482C19.4496 43.6186 19.5454 43.8499 19.5454 44.091Z" fill="#99BBD9"/>
    <path d="M14.5454 40.4546H9.99996C9.74892 40.4546 9.54541 40.6581 9.54541 40.9091C9.54541 41.1602 9.74892 41.3637 9.99996 41.3637H14.5454C14.7964 41.3637 15 41.1602 15 40.9091C15 40.6581 14.7964 40.4546 14.5454 40.4546Z" fill="white"/>
    <path d="M14.5454 37.7273H9.99996C9.74892 37.7273 9.54541 37.9308 9.54541 38.1818C9.54541 38.4329 9.74892 38.6364 9.99996 38.6364H14.5454C14.7964 38.6364 15 38.4329 15 38.1818C15 37.9308 14.7964 37.7273 14.5454 37.7273Z" fill="white"/>
    <path d="M38.8889 37.0372C45.0254 37.0372 50.0001 32.0625 50.0001 25.9261C50.0001 19.7896 45.0254 14.8149 38.8889 14.8149C32.7524 14.8149 27.7778 19.7896 27.7778 25.9261C27.7778 32.0625 32.7524 37.0372 38.8889 37.0372Z" fill="#FF005B"/>
    <path d="M45.3702 24.1121C45.3692 24.5891 45.284 25.0618 45.119 25.5055C44.1217 28.1332 42.3476 30.3458 40.0635 31.8105L39.3019 32.2922C39.1788 32.3677 39.039 32.4074 38.8968 32.4074C38.7546 32.4074 38.6149 32.3677 38.4917 32.2922L37.7302 31.8105C35.4441 30.3553 33.6644 28.152 32.6584 25.5313C32.4904 25.0795 32.4052 24.5978 32.4073 24.1121C32.4073 23.1197 32.7786 22.168 33.4395 21.4663C34.1004 20.7646 34.9969 20.3704 35.9316 20.3704H36.1422C36.6062 20.3682 37.066 20.4645 37.4945 20.6536C37.923 20.8426 38.3117 21.1207 38.6376 21.4714L38.8887 21.738L39.1399 21.4714C39.4658 21.1207 39.8544 20.8426 40.2829 20.6536C40.7114 20.4645 41.1712 20.3682 41.6352 20.3704H41.8459C42.7806 20.3704 43.677 20.7646 44.3379 21.4663C44.9989 22.168 45.3702 23.1197 45.3702 24.1121Z" fill="white"/>
    <path d="M6.94464 19.4445C9.75721 19.4445 12.0372 17.1644 12.0372 14.3519C12.0372 11.5393 9.75721 9.25928 6.94464 9.25928C4.13208 9.25928 1.85205 11.5393 1.85205 14.3519C1.85205 17.1644 4.13208 19.4445 6.94464 19.4445Z" fill="#FF005B"/>
    <path d="M28.2409 11.1112C30.5421 11.1112 32.4076 9.24568 32.4076 6.9445C32.4076 4.64331 30.5421 2.77783 28.2409 2.77783C25.9397 2.77783 24.0742 4.64331 24.0742 6.9445C24.0742 9.24568 25.9397 11.1112 28.2409 11.1112Z" fill="#FF005B"/>
    <path d="M10.1851 13.7641C10.1846 13.9842 10.142 14.2024 10.0595 14.4072C9.56087 15.6199 8.67378 16.6412 7.53174 17.3172L7.15096 17.5395C7.08938 17.5743 7.01952 17.5927 6.94842 17.5927C6.87731 17.5927 6.80745 17.5743 6.74587 17.5395L6.36508 17.3172C5.22203 16.6456 4.33219 15.6286 3.82921 14.4191C3.74522 14.2106 3.7026 13.9882 3.70363 13.7641C3.70363 13.306 3.88929 12.8668 4.21975 12.5429C4.55022 12.2191 4.99843 12.0371 5.46578 12.0371H5.5711C5.80312 12.0361 6.03301 12.0806 6.24727 12.1678C6.46152 12.2551 6.65583 12.3834 6.81879 12.5453L6.94436 12.6683L7.06994 12.5453C7.23289 12.3834 7.4272 12.2551 7.64146 12.1678C7.85572 12.0806 8.0856 12.0361 8.31762 12.0371H8.42295C8.8903 12.0371 9.33851 12.2191 9.66897 12.5429C9.99944 12.8668 10.1851 13.306 10.1851 13.7641Z" fill="white"/>
    <path d="M30.5554 6.06876C30.555 6.25224 30.5246 6.43403 30.4657 6.6047C30.1095 7.61534 29.4759 8.46636 28.6602 9.02968L28.3882 9.21495C28.3442 9.24398 28.2943 9.25927 28.2435 9.25927C28.1927 9.25927 28.1428 9.24398 28.0988 9.21495L27.8268 9.02968C27.0104 8.47001 26.3748 7.62256 26.0155 6.61463C25.9555 6.44085 25.9251 6.25558 25.9258 6.06876C25.9258 5.68708 26.0584 5.32104 26.2945 5.05116C26.5305 4.78127 26.8506 4.62965 27.1845 4.62965H27.2597C27.4254 4.62882 27.5896 4.66584 27.7427 4.73856C27.8957 4.81128 28.0345 4.91822 28.1509 5.05311L28.2406 5.15567L28.3303 5.05311C28.4467 4.91822 28.5855 4.81128 28.7385 4.73856C28.8916 4.66584 29.0558 4.62882 29.2215 4.62965H29.2967C29.6306 4.62965 29.9507 4.78127 30.1868 5.05116C30.4228 5.32104 30.5554 5.68708 30.5554 6.06876Z" fill="white"/>
  </g>
  <defs>
    <radialGradient id="paint0_radial_4616_123689" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.1819 26.8183) rotate(90) scale(22.7273)">
      <stop offset="0.545" stop-color="white"/>
      <stop offset="1" stop-color="#FFFDC0"/>
    </radialGradient>
    <clipPath id="clip0_4616_123689">
      <rect width="50" height="50" fill="white"/>
    </clipPath>
  </defs>
</svg>
`
