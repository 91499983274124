export default `
<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
  <path
    d="M25.0002 48.4543C37.5521 48.4543 47.7275 38.279 47.7275 25.727C47.7275 13.1751 37.5521 2.99976 25.0002 2.99976C12.4483 2.99976 2.27295 13.1751 2.27295 25.727C2.27295 38.279 12.4483 48.4543 25.0002 48.4543Z"
    fill="url(#paint0_radial_4616_123632)" />
  <path
    d="M38.6362 19.5455V21.3637C38.6362 21.7254 38.4926 22.0722 38.2368 22.3279C37.9811 22.5837 37.6343 22.7273 37.2726 22.7273H2.38623C2.53648 21.1837 2.84881 19.6601 3.31805 18.1819H37.2726C37.6343 18.1819 37.9811 18.3256 38.2368 18.5813C38.4926 18.837 38.6362 19.1839 38.6362 19.5455Z"
    fill="#99BBD9" />
  <path
    d="M36.3634 22.7273L33.9589 40.3682C33.8698 41.0221 33.5466 41.6215 33.0493 42.0553C32.552 42.4891 31.9142 42.7278 31.2543 42.7273H10.7998C8.1383 40.6026 5.98966 37.9056 4.51341 34.8366C3.03716 31.7677 2.27123 28.4056 2.27249 25C2.27124 24.241 2.30916 23.4824 2.38613 22.7273H36.3634Z"
    fill="#0055A1" />
  <path
    d="M18.1819 1.36353C18.7847 1.36353 19.3627 1.60297 19.789 2.02919C20.2152 2.45541 20.4546 3.03349 20.4546 3.63625V18.1817H15.9092V3.63625C15.9092 3.03349 16.1486 2.45541 16.5748 2.02919C17.0011 1.60297 17.5791 1.36353 18.1819 1.36353Z"
    fill="#0055A1" />
  <path
    d="M19.5456 27.2728C19.5456 26.5197 18.9351 25.9092 18.182 25.9092C17.4289 25.9092 16.8184 26.5197 16.8184 27.2728V38.1819C16.8184 38.935 17.4289 39.5455 18.182 39.5455C18.9351 39.5455 19.5456 38.935 19.5456 38.1819V27.2728Z"
    fill="white" />
  <path
    d="M27.2727 27.2728C27.2727 26.5197 26.6622 25.9092 25.909 25.9092C25.1559 25.9092 24.5454 26.5197 24.5454 27.2728V38.1819C24.5454 38.935 25.1559 39.5455 25.909 39.5455C26.6622 39.5455 27.2727 38.935 27.2727 38.1819V27.2728Z"
    fill="white" />
  <path
    d="M9.09109 38.1818C9.09109 38.9349 9.70161 39.5454 10.4547 39.5454C11.2078 39.5454 11.8184 38.9349 11.8184 38.1818V27.2727C11.8184 26.5196 11.2078 25.909 10.4547 25.909C9.70161 25.909 9.09109 26.5196 9.09109 27.2727V38.1818Z"
    fill="white" />
  <path
    d="M35.4544 49.5455C39.22 49.5455 42.2726 46.4929 42.2726 42.7274C42.2726 38.9618 39.22 35.9092 35.4544 35.9092C31.6888 35.9092 28.6362 38.9618 28.6362 42.7274C28.6362 46.4929 31.6888 49.5455 35.4544 49.5455Z"
    fill="#FF005B" />
  <path
    d="M31.7138 10.7408L27.6229 15.7408C27.5803 15.7923 27.5268 15.8337 27.4663 15.8621C27.4058 15.8906 27.3398 15.9053 27.2729 15.9053C27.2061 15.9053 27.1401 15.8906 27.0796 15.8621C27.0191 15.8337 26.9656 15.7923 26.9229 15.7408L22.832 10.7408C22.7781 10.6743 22.744 10.5939 22.7337 10.5089C22.7235 10.4239 22.7374 10.3377 22.774 10.2603C22.8106 10.1828 22.8683 10.1173 22.9405 10.0713C23.0127 10.0252 23.0964 10.0005 23.182 9.99989H25.0002V1.81807C25.0002 1.69752 25.0481 1.5819 25.1333 1.49666C25.2186 1.41141 25.3342 1.36353 25.4548 1.36353H29.0911C29.2117 1.36353 29.3273 1.41141 29.4125 1.49666C29.4978 1.5819 29.5457 1.69752 29.5457 1.81807V9.99989H31.3638C31.4495 10.0005 31.5332 10.0252 31.6054 10.0713C31.6776 10.1173 31.7353 10.1828 31.7718 10.2603C31.8084 10.3377 31.8224 10.4239 31.8121 10.5089C31.8019 10.5939 31.7678 10.6743 31.7138 10.7408Z"
    fill="#FF005B" />
  <path
    d="M38.6365 41.8183H36.3638V39.5456C36.3638 39.3045 36.268 39.0732 36.0975 38.9027C35.927 38.7323 35.6958 38.6365 35.4547 38.6365C35.2136 38.6365 34.9823 38.7323 34.8119 38.9027C34.6414 39.0732 34.5456 39.3045 34.5456 39.5456V41.8183H32.2729C32.0318 41.8183 31.8005 41.9141 31.63 42.0846C31.4595 42.255 31.3638 42.4863 31.3638 42.7274C31.3638 42.9685 31.4595 43.1997 31.63 43.3702C31.8005 43.5407 32.0318 43.6365 32.2729 43.6365H34.5456V45.9092C34.5456 46.1503 34.6414 46.3815 34.8119 46.552C34.9823 46.7225 35.2136 46.8183 35.4547 46.8183C35.6958 46.8183 35.927 46.7225 36.0975 46.552C36.268 46.3815 36.3638 46.1503 36.3638 45.9092V43.6365H38.6365C38.8776 43.6365 39.1088 43.5407 39.2793 43.3702C39.4498 43.1997 39.5456 42.9685 39.5456 42.7274C39.5456 42.4863 39.4498 42.255 39.2793 42.0846C39.1088 41.9141 38.8776 41.8183 38.6365 41.8183Z"
    fill="white" />
  <path
    d="M18.1818 23.6363C19.9391 23.6363 21.3636 22.2118 21.3636 20.4545C21.3636 18.6973 19.9391 17.2727 18.1818 17.2727C16.4245 17.2727 15 18.6973 15 20.4545C15 22.2118 16.4245 23.6363 18.1818 23.6363Z"
    fill="#6699C7" />
  <path
    d="M18.182 21.8181C18.9351 21.8181 19.5456 21.2076 19.5456 20.4545C19.5456 19.7013 18.9351 19.0908 18.182 19.0908C17.4289 19.0908 16.8184 19.7013 16.8184 20.4545C16.8184 21.2076 17.4289 21.8181 18.182 21.8181Z"
    fill="white" />
  <defs>
    <radialGradient id="paint0_radial_4616_123632" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(25.0002 25.727) rotate(90) scale(22.7273)">
      <stop offset="0.545" stop-color="white" />
      <stop offset="1" stop-color="#FFFDC0" />
    </radialGradient>
  </defs>
</svg>
`
