export default `
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <mask id="mask0_5371_157546" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
    <rect width="20" height="20" fill="#D9D9D9" />
  </mask>
  <g mask="url(#mask0_5371_157546)">
    <path
      d="M8.35417 18.3334L6.875 16.8542L13.7292 10.0001L6.875 3.14591L8.35417 1.66675L16.6875 10.0001L8.35417 18.3334Z"
      fill="#8F8F8F" />
  </g>
</svg>
`
