export default `
<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
  <mask id="mask0_5772_125818" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
    <rect x="0.0922852" width="24" height="24" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_5772_125818)">
    <path d="M17.5923 18.8V16H16.5923V19.2L18.7423 21.35L19.4423 20.65L17.5923 18.8ZM11.0923 4L5.16729 7.425L11.0923 10.85L17.0173 7.425L11.0923 4ZM2.09229 15.975V8.025C2.09229 7.65833 2.17979 7.325 2.35479 7.025C2.52979 6.725 2.77562 6.48333 3.09229 6.3L10.0923 2.275C10.259 2.19167 10.4215 2.125 10.5798 2.075C10.7381 2.025 10.909 2 11.0923 2C11.2756 2 11.4506 2.025 11.6173 2.075C11.784 2.125 11.9423 2.19167 12.0923 2.275L19.0923 6.3C19.409 6.48333 19.6548 6.725 19.8298 7.025C20.0048 7.325 20.0923 7.65833 20.0923 8.025V12H18.0923V9.1L11.0673 13.15L4.09229 9.1V15.95L10.0923 19.425V21.725L3.09229 17.7C2.77562 17.5167 2.52979 17.275 2.35479 16.975C2.17979 16.675 2.09229 16.3417 2.09229 15.975ZM17.0923 24C15.709 24 14.5298 23.5125 13.5548 22.5375C12.5798 21.5625 12.0923 20.3833 12.0923 19C12.0923 17.6167 12.5798 16.4375 13.5548 15.4625C14.5298 14.4875 15.709 14 17.0923 14C18.4756 14 19.6548 14.4875 20.6298 15.4625C21.6048 16.4375 22.0923 17.6167 22.0923 19C22.0923 20.3833 21.6048 21.5625 20.6298 22.5375C19.6548 23.5125 18.4756 24 17.0923 24Z" fill="#8F8F8F"/>
  </g>
</svg>
`
