export default `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <mask id="mask0_14280_87512" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
    <rect width="24" height="24" fill="#D9D9D9" />
  </mask>
  <g mask="url(#mask0_14280_87512)">
    <path d="M6 13V11H18V13H6Z" fill="#0055A1" />
  </g>
</svg>
`
