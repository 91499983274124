import { booleanAttribute, Component, computed, effect, input, numberAttribute, output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BehaviorSubject, debounceTime, skip, Subscription } from 'rxjs'
import { IconModule, IconName } from '@shared/components/v2/icon/icon.component'
import { ButtonColor, ButtonSize, ButtonType } from '@shared/components/v2/core/button/button.enum'

@Component({
  selector: 'app-button',
  imports: [CommonModule, IconModule],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  public stopPropagation = input(false, {
    transform: booleanAttribute,
  })
  public type = input<ButtonType>(ButtonType.Default)
  public size = input<ButtonSize>(ButtonSize.Large)
  public color = input(ButtonColor.Pink)
  public iconName = input<IconName>('alarmBlack')
  public iconSize = input(16, {
    transform: numberAttribute,
  })
  public debounceMs = input(0, {
    transform: numberAttribute,
  })
  public fluid = input(false, {
    transform: booleanAttribute,
  })
  public disabled = input(false, {
    transform: booleanAttribute,
  })
  public noPadding = input(false, {
    transform: booleanAttribute,
  })

  public clicked = output()

  private readonly click$ = new BehaviorSubject<null>(null)

  protected isIcon = computed(() => this.type() === ButtonType.Icon)

  protected classes = computed(() => {
    const classes = 'font-sarabun rounded-lg px-4 font-bold border border-transparent'
    return `${classes} btn-${this.type()} ${this.color()} ${this.sizeClass()} ${this.disabledClass()}`
  })

  private readonly sizeClass = computed(() => {
    return this.size() === ButtonSize.Small ? 'text-base py-1.5' : 'text-xl py-2'
  })

  private readonly disabledClass = computed(() => {
    let classes = 'disabled:text-neutral-black-20 disabled:cursor-not-allowed '
    if (this.type() === 'primary') {
      classes += 'disabled:bg-neutral-grey-60 disabled:border-neutral-grey-60'
    } else if (this.type() === 'text') {
      classes += 'disabled:border-transparent'
    } else {
      classes += 'disabled:border-neutral-grey-60'
    }
    return classes
  })

  constructor() {
    this.debounceClick()
  }

  private debounceClick() {
    let sub: Subscription
    effect(() => {
      sub?.unsubscribe()
      sub = this.click$.pipe(skip(1), debounceTime(this.debounceMs())).subscribe(() => {
        this.clicked.emit()
      })
    })
  }

  protected handleClick(e: MouseEvent) {
    if (this.stopPropagation()) {
      e.stopPropagation()
    }
    this.click$.next(null)
  }
}
