export default `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.85643 11.114L6.56819 8.971C6.61904 8.94165 6.67324 8.9345 6.72999 8.9497L7.41354 9.13285C7.52694 9.16325 7.64424 9.0955 7.67464 8.9821L7.85779 8.29855C7.87299 8.24185 7.90629 8.19845 7.95714 8.1691L17.7265 2.52878C17.8213 2.47403 17.941 2.49938 18.0054 2.58793C18.4996 3.26673 19.4058 3.50953 20.1731 3.16873C20.2732 3.12428 20.3895 3.16213 20.4443 3.25693L21.2127 4.58784C21.2674 4.68269 21.242 4.80234 21.1535 4.86679C20.4237 5.39804 20.1979 6.40544 20.6593 7.20464C21.1207 8.00385 22.1061 8.31195 22.931 7.9456C23.0311 7.90115 23.1474 7.93895 23.2022 8.0338L23.9706 9.3647C24.0253 9.45955 24 9.5792 23.9114 9.64365C23.2326 10.1378 22.9898 11.044 23.3306 11.8113C23.375 11.9114 23.3372 12.0277 23.2424 12.0825L13.473 17.7229C13.4222 17.7523 13.368 17.7594 13.3113 17.7442L12.6278 17.5611C12.5144 17.5307 12.397 17.5984 12.3667 17.7118L12.1835 18.3954C12.1683 18.4521 12.135 18.4955 12.0842 18.5248L8.37244 20.6678C8.27759 20.7225 8.15794 20.6972 8.09349 20.6086C7.59934 19.9298 6.69314 19.6871 5.92584 20.0278C5.82574 20.0723 5.70944 20.0345 5.65469 19.9396L4.88628 18.6087C4.83153 18.5139 4.85693 18.3943 4.94548 18.3298C5.67524 17.7985 5.90109 16.7911 5.43969 15.9919C4.97823 15.1927 3.99288 14.8846 3.16793 15.2509C3.06783 15.2954 2.95153 15.2575 2.89678 15.1627L2.12838 13.8318C2.07363 13.737 2.09903 13.6173 2.18758 13.5529C2.86638 13.0587 3.10918 12.1525 2.76843 11.3852C2.72378 11.2851 2.76158 11.1688 2.85643 11.114Z" fill="#3377B4"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M22.4792 11.7846L13.1322 17.1811C13.0814 17.2104 13.0271 17.2176 12.9704 17.2024L12.2869 17.0192C12.1735 16.9888 12.0561 17.0566 12.0258 17.17L11.8426 17.8535C11.8274 17.9102 11.7941 17.9536 11.7433 17.983L7.77539 20.2739C7.89334 20.3695 8.00054 20.4815 8.09319 20.6087C8.15764 20.6973 8.27729 20.7227 8.37214 20.6679L12.0839 18.5249C12.1348 18.4956 12.1681 18.4522 12.1832 18.3955L12.3664 17.7119C12.3968 17.5985 12.5141 17.5308 12.6275 17.5612L13.311 17.7443C13.3677 17.7595 13.4219 17.7524 13.4727 17.723L23.2421 12.0827C23.3369 12.0279 23.3747 11.9116 23.3303 11.8115C22.9895 11.0442 23.2323 10.138 23.9111 9.64385C23.9997 9.5794 24.025 9.45975 23.9703 9.3649L23.2019 8.03399C23.1471 7.93914 23.0308 7.90134 22.9307 7.94579C22.8294 7.99079 22.7256 8.02564 22.6206 8.05064L23.2073 9.06685C23.2621 9.1617 23.2367 9.28135 23.1482 9.3458C22.4694 9.83995 22.2266 10.7461 22.5673 11.5135C22.6119 11.6135 22.574 11.7298 22.4792 11.7846Z" fill="#0055A1"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3814 10.4302C17.2991 10.4777 17.2438 10.5541 17.221 10.6391C17.1982 10.7241 17.2079 10.8179 17.2554 10.9003C17.303 10.9827 17.3794 11.038 17.4644 11.0608C17.5494 11.0836 17.6432 11.0739 17.7256 11.0263C17.8079 10.9788 17.8632 10.9024 17.886 10.8174C17.9088 10.7324 17.8991 10.6385 17.8516 10.5562C17.804 10.4738 17.7276 10.4185 17.6426 10.3957C17.5576 10.3729 17.4638 10.3826 17.3814 10.4302ZM15.5818 7.34301C15.6038 7.12401 15.7992 6.96436 16.0182 6.98641C16.2371 7.00846 16.3968 7.20386 16.3748 7.42281L15.8441 12.7398C15.822 12.9588 15.6266 13.1185 15.4077 13.0964C15.1887 13.0744 15.029 12.879 15.0511 12.66L15.5818 7.34301ZM13.6774 9.07162C13.595 9.11917 13.5397 9.19557 13.517 9.28057C13.4942 9.36562 13.5039 9.45942 13.5514 9.54177C13.599 9.62412 13.6754 9.67942 13.7604 9.70222C13.8454 9.72497 13.9392 9.71532 14.0216 9.66777C14.1039 9.62022 14.1592 9.54382 14.182 9.45882C14.2048 9.37377 14.1951 9.27997 14.1475 9.19762C14.1 9.11522 14.0236 9.05997 13.9386 9.03717C13.8535 9.01437 13.7597 9.02407 13.6774 9.07162ZM12.7443 9.07357C12.6685 9.35642 12.7006 9.66817 12.8586 9.94182C13.0165 10.2154 13.2705 10.3991 13.5533 10.4749C13.8362 10.5507 14.1479 10.5186 14.4215 10.3606C14.6951 10.2026 14.8788 9.94872 14.9546 9.66587C15.0304 9.38302 14.9983 9.07127 14.8403 8.79766C14.6823 8.52401 14.4284 8.34031 14.1456 8.26456C13.8627 8.18876 13.551 8.22086 13.2773 8.37886C13.0038 8.53676 12.8201 8.79072 12.7443 9.07357ZM16.4483 10.4321C16.5241 10.1492 16.7078 9.89527 16.9814 9.73732C17.2551 9.57932 17.5668 9.54722 17.8497 9.62302C18.1325 9.69882 18.3864 9.88252 18.5444 10.1561C18.7024 10.4297 18.7345 10.7415 18.6587 11.0243C18.5829 11.3072 18.3992 11.5611 18.1256 11.7191C17.852 11.8771 17.5403 11.9092 17.2574 11.8334C16.9746 11.7576 16.7206 11.5739 16.5626 11.3003C16.4047 11.0267 16.3725 10.7149 16.4483 10.4321Z" fill="#ECEFF1"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.85648 11.114L6.56824 8.97095C6.61909 8.9416 6.67329 8.93445 6.73004 8.94965L7.28999 9.0997C7.26739 9.18405 7.27699 9.27695 7.32409 9.35855L7.82509 10.2263C7.91929 10.3895 8.12794 10.4454 8.29109 10.3512L8.52429 10.7551C8.36114 10.8493 8.30524 11.0579 8.39944 11.2211L8.90045 12.0888C8.99465 12.252 9.2033 12.3079 9.36645 12.2137L9.59965 12.6176C9.4365 12.7118 9.3806 12.9204 9.4748 13.0836L9.9758 13.9513C10.07 14.1145 10.2786 14.1704 10.4418 14.0762L10.675 14.4801C10.5118 14.5743 10.4559 14.7829 10.5501 14.946L11.0511 15.8138C11.1453 15.9769 11.3539 16.0328 11.5171 15.9386L11.7503 16.3425C11.5871 16.4367 11.5312 16.6453 11.6254 16.8085L12.1264 17.6762C12.1735 17.7578 12.2492 17.8126 12.3336 17.8351L12.1835 18.3951C12.1683 18.4518 12.135 18.4952 12.0842 18.5245L8.37244 20.6675C8.27759 20.7222 8.15794 20.6969 8.09349 20.6083C7.59934 19.9295 6.69314 19.6868 5.92584 20.0275C5.82574 20.072 5.70944 20.0342 5.65469 19.9393L4.88628 18.6084C4.83153 18.5136 4.85693 18.394 4.94548 18.3295C5.67524 17.7982 5.90109 16.7908 5.43969 15.9916C4.97823 15.1924 3.99288 14.8843 3.16793 15.2506C3.06783 15.2951 2.95153 15.2572 2.89678 15.1624L2.12838 13.8315C2.07363 13.7367 2.09903 13.617 2.18758 13.5526C2.86638 13.0584 3.10918 12.1522 2.76843 11.3849C2.72383 11.285 2.76163 11.1687 2.85648 11.114Z" fill="#D8ECFE"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.70552 13.3996C5.51422 13.5101 5.26957 13.4445 5.15912 13.2532C5.04867 13.0619 5.11422 12.8173 5.30552 12.7068L7.15193 11.6408C7.34323 11.5304 7.58788 11.5959 7.69833 11.7872C7.80878 11.9785 7.74323 12.2232 7.55193 12.3336L5.70552 13.3996ZM8.21068 17.7386C8.01938 17.8491 7.77473 17.7835 7.66428 17.5922C7.55383 17.4009 7.61938 17.1563 7.81068 17.0458L9.65708 15.9798C9.84838 15.8694 10.093 15.9349 10.2035 16.1262C10.3139 16.3175 10.2484 16.5622 10.0571 16.6726L8.21068 17.7386ZM7.37563 16.2923L9.22203 15.2263C9.41333 15.1158 9.47888 14.8712 9.36843 14.6799C9.25798 14.4886 9.01333 14.423 8.82203 14.5335L6.97563 15.5995C6.78433 15.7099 6.71878 15.9546 6.82923 16.1459C6.93968 16.3372 7.18433 16.4027 7.37563 16.2923ZM6.54058 14.8459L8.38698 13.7799C8.57828 13.6695 8.64383 13.4248 8.53338 13.2335C8.42293 13.0422 8.17828 12.9767 7.98698 13.0871L6.14057 14.1531C5.94927 14.2636 5.88372 14.5082 5.99417 14.6995C6.10462 14.8909 6.34927 14.9564 6.54058 14.8459Z" fill="#ADD5FA"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.98961 11.9165H6.27557C6.33427 11.9165 6.38482 11.9374 6.42632 11.979L6.92667 12.4793C7.00972 12.5624 7.14517 12.5624 7.22817 12.4793L7.72852 11.979C7.77002 11.9375 7.82057 11.9165 7.87927 11.9165H19.1601C19.2696 11.9165 19.3605 11.9983 19.3721 12.1072C19.4606 12.9422 20.124 13.6055 20.9589 13.6941C21.0678 13.7056 21.1496 13.7965 21.1496 13.9061V15.4429C21.1496 15.5524 21.0678 15.6433 20.9589 15.6548C20.0613 15.75 19.362 16.5096 19.362 17.4324C19.362 18.3553 20.0613 19.1148 20.9589 19.21C21.0678 19.2215 21.1496 19.3124 21.1496 19.422V20.9588C21.1496 21.0683 21.0679 21.1592 20.9589 21.1708C20.124 21.2593 19.4606 21.9227 19.3721 22.7576C19.3605 22.8665 19.2696 22.9483 19.1601 22.9483H7.87932C7.82062 22.9483 7.77007 22.9273 7.72857 22.8858L7.22822 22.3855C7.14517 22.3024 7.00972 22.3024 6.92672 22.3855L6.42637 22.8858C6.38487 22.9273 6.33432 22.9483 6.27562 22.9483H1.98961C1.88011 22.9483 1.78915 22.8665 1.7776 22.7576C1.68905 21.9227 1.02565 21.2593 0.190751 21.1708C0.0818503 21.1592 0 21.0683 0 20.9588V19.422C0 19.3124 0.0818003 19.2216 0.190751 19.21C1.08835 19.1148 1.78765 18.3553 1.78765 17.4324C1.78765 16.5096 1.08835 15.75 0.190751 15.6548C0.0818503 15.6433 0 15.5524 0 15.4429V13.9061C0 13.7965 0.0818003 13.7056 0.190751 13.6941C1.0257 13.6055 1.68905 12.9422 1.7776 12.1072C1.78915 11.9983 1.88011 11.9165 1.98961 11.9165Z" fill="#E93374"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.6485 22.3087H7.85544C7.79674 22.3087 7.74619 22.2878 7.70469 22.2463L7.20434 21.7459C7.12134 21.6629 6.98584 21.6629 6.90284 21.7459L6.40249 22.2463C6.36099 22.2878 6.31044 22.3087 6.25174 22.3087H1.66992C1.72427 22.4506 1.76112 22.6011 1.77772 22.7576C1.78927 22.8665 1.88017 22.9483 1.98972 22.9483H6.27569C6.33439 22.9483 6.38494 22.9274 6.42644 22.8859L6.92679 22.3855C7.00984 22.3025 7.14529 22.3025 7.22829 22.3855L7.72864 22.8859C7.77014 22.9274 7.82069 22.9483 7.87939 22.9483H19.1601C19.2696 22.9483 19.3605 22.8665 19.3721 22.7576C19.4606 21.9227 20.124 21.2593 20.9589 21.1708C21.0678 21.1593 21.1496 21.0684 21.1496 20.9588V19.422C21.1496 19.3125 21.0678 19.2216 20.9589 19.21C20.8486 19.1983 20.7413 19.1766 20.6379 19.1458V20.3192C20.6379 20.4287 20.5561 20.5196 20.4472 20.5312C19.6123 20.6197 18.9489 21.2831 18.8604 22.118C18.8489 22.2269 18.758 22.3087 18.6485 22.3087Z" fill="#E40051"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.909 18.5861C14.8139 18.5861 14.7278 18.6246 14.6656 18.6868C14.6034 18.7491 14.5649 18.8351 14.5649 18.9302C14.5649 19.0253 14.6034 19.1114 14.6656 19.1736C14.7279 19.2359 14.8139 19.2744 14.909 19.2744C15.0041 19.2744 15.0902 19.2359 15.1524 19.1736C15.2146 19.1114 15.2532 19.0253 15.2532 18.9302C15.2532 18.8351 15.2147 18.749 15.1524 18.6868C15.0902 18.6246 15.0041 18.5861 14.909 18.5861ZM14.894 15.0127C15.0226 14.8341 15.2716 14.7935 15.4503 14.9221C15.6289 15.0507 15.6695 15.2997 15.5409 15.4784L12.4228 19.8177C12.2942 19.9963 12.0452 20.0369 11.8665 19.9083C11.6879 19.7797 11.6473 19.5307 11.7759 19.352L14.894 15.0127ZM12.3805 15.5575C12.2854 15.5575 12.1993 15.596 12.1371 15.6583C12.0748 15.7205 12.0363 15.8066 12.0363 15.9017C12.0363 15.9968 12.0748 16.0829 12.1371 16.1451C12.1993 16.2073 12.2854 16.2458 12.3805 16.2458C12.4756 16.2458 12.5617 16.2073 12.6239 16.1451C12.6861 16.0828 12.7246 15.9968 12.7246 15.9017C12.7246 15.8065 12.6861 15.7205 12.6239 15.6582C12.5617 15.596 12.4756 15.5575 12.3805 15.5575ZM11.5714 15.0927C11.3644 15.2997 11.2363 15.5858 11.2363 15.9017C11.2363 16.2177 11.3644 16.5037 11.5714 16.7108C11.7785 16.9178 12.0645 17.0459 12.3805 17.0459C12.6964 17.0459 12.9825 16.9179 13.1895 16.7108C13.3966 16.5037 13.5247 16.2177 13.5247 15.9017C13.5247 15.5858 13.3966 15.2997 13.1896 15.0927C12.9825 14.8856 12.6965 14.7576 12.3805 14.7576C12.0646 14.7575 11.7785 14.8856 11.5714 15.0927ZM14.1 18.1212C14.307 17.9142 14.5931 17.7861 14.909 17.7861C15.225 17.7861 15.511 17.9141 15.7181 18.1212C15.9251 18.3282 16.0532 18.6143 16.0532 18.9302C16.0532 19.2462 15.9251 19.5322 15.718 19.7393C15.511 19.9463 15.2249 20.0744 14.909 20.0744C14.593 20.0744 14.307 19.9464 14.0999 19.7393C13.8929 19.5322 13.7648 19.2462 13.7648 18.9302C13.7648 18.6143 13.8929 18.3283 14.1 18.1212Z" fill="#ECEFF1"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.98961 11.9165H6.27557C6.33427 11.9165 6.38482 11.9374 6.42632 11.979L6.83622 12.3889C6.77452 12.4506 6.73632 12.5359 6.73632 12.6301V13.6321C6.73632 13.8205 6.88907 13.9732 7.07742 13.9732V14.4396C6.88902 14.4396 6.73632 14.5923 6.73632 14.7807V15.7827C6.73632 15.9711 6.88907 16.1238 7.07742 16.1238V16.5902C6.88902 16.5902 6.73632 16.7429 6.73632 16.9313V17.9333C6.73632 18.1217 6.88907 18.2744 7.07742 18.2744V18.7408C6.88902 18.7408 6.73632 18.8935 6.73632 19.0819V20.0839C6.73632 20.2722 6.88907 20.425 7.07742 20.425V20.8914C6.88902 20.8914 6.73632 21.0441 6.73632 21.2325V22.2345C6.73632 22.3287 6.77452 22.414 6.83622 22.4757L6.42632 22.8856C6.38482 22.9271 6.33427 22.948 6.27557 22.948H1.98961C1.88011 22.948 1.78916 22.8662 1.7776 22.7573C1.68905 21.9224 1.02565 21.259 0.190751 21.1705C0.0818503 21.159 0 21.0681 0 20.9585V19.4217C0 19.3122 0.0818003 19.2213 0.190751 19.2097C1.08835 19.1145 1.78765 18.355 1.78765 17.4322C1.78765 16.5093 1.08835 15.7498 0.190751 15.6546C0.0818503 15.643 0 15.5521 0 15.4426V13.9058C0 13.7963 0.0818003 13.7054 0.190751 13.6938C1.0257 13.6053 1.68905 12.9419 1.7776 12.107C1.78916 11.9983 1.88011 11.9165 1.98961 11.9165Z" fill="#FFE066"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.42639 22.8859L6.83629 22.476C6.77459 22.4143 6.73639 22.329 6.73639 22.2348V21.9124L6.40239 22.2464C6.36089 22.2879 6.31034 22.3088 6.25164 22.3088H1.66992C1.72517 22.453 1.76147 22.6042 1.77772 22.7577C1.78927 22.8666 1.88017 22.9484 1.98972 22.9484H6.27568C6.33438 22.9484 6.38489 22.9274 6.42639 22.8859Z" fill="#FFCB00"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.31406 15.3205C3.09316 15.3205 2.91406 15.1414 2.91406 14.9205C2.91406 14.6996 3.09316 14.5205 3.31406 14.5205H5.44607C5.66697 14.5205 5.84607 14.6996 5.84607 14.9205C5.84607 15.1414 5.66697 15.3205 5.44607 15.3205H3.31406ZM3.31406 20.3308C3.09316 20.3308 2.91406 20.1517 2.91406 19.9308C2.91406 19.7099 3.09316 19.5308 3.31406 19.5308H5.44607C5.66697 19.5308 5.84607 19.7099 5.84607 19.9308C5.84607 20.1517 5.66697 20.3308 5.44607 20.3308H3.31406ZM3.31406 18.6607C3.09316 18.6607 2.91406 18.4816 2.91406 18.2607C2.91406 18.0398 3.09316 17.8607 3.31406 17.8607H5.44607C5.66697 17.8607 5.84607 18.0398 5.84607 18.2607C5.84607 18.4816 5.66697 18.6607 5.44607 18.6607H3.31406ZM3.31406 16.9906C3.09316 16.9906 2.91406 16.8115 2.91406 16.5906C2.91406 16.3697 3.09316 16.1906 3.31406 16.1906H5.44607C5.66697 16.1906 5.84607 16.3697 5.84607 16.5906C5.84607 16.8115 5.66697 16.9906 5.44607 16.9906H3.31406Z" fill="#FFCB00"/>
</svg>
`
