export default `
<svg xmlns="http://www.w3.org/2000/svg" width="49" height="50" viewBox="0 0 49 50" fill="none">
  <g clip-path="url(#clip0_4647_130972)">
    <path d="M35.6497 19.1521C34.692 18.199 33.5251 17.4518 32.1819 16.9313C30.8427 16.4148 29.3352 16.1526 27.7013 16.1526C26.0673 16.1526 24.4284 16.2522 22.8523 16.4489C21.271 16.6442 19.816 16.9444 18.5281 17.3403L18.315 17.4059V22.8975L18.6834 22.8149C20.2817 22.457 21.9353 22.1542 23.5955 21.9143C25.2412 21.6757 26.6423 21.6337 27.7605 21.7924C28.6801 21.9143 29.4141 22.1961 29.939 22.6314C30.4771 23.0758 30.8979 23.5884 31.1887 24.1521C31.4821 24.7236 31.6702 25.2927 31.7478 25.8419C31.8267 26.4135 31.8675 26.8934 31.8675 27.2681C31.8675 27.6431 31.8203 28.148 31.7242 28.7641C31.6322 29.3671 31.4413 29.9637 31.1625 30.5378C30.8901 31.095 30.4717 31.5958 29.922 32.0232C29.3905 32.4348 28.6602 32.6445 27.7513 32.6445C26.6146 32.6445 25.6689 32.3535 24.9427 31.7792C24.227 31.2157 23.8797 30.3805 23.8797 29.2255C23.8797 28.7758 23.9126 28.2935 23.9744 27.7939C24.0376 27.2944 24.1309 26.8528 24.2558 26.4818L24.3887 26.0857H18.3282L18.2466 26.2587C18.0072 26.7701 17.8177 27.3691 17.6822 28.0404C17.5481 28.7129 17.481 29.375 17.481 30.0081C17.481 31.6233 17.8177 32.9997 18.4821 34.1035C19.1412 35.1982 19.9818 36.0872 20.9816 36.7439C21.9722 37.3967 23.0627 37.8649 24.223 38.1323C25.3715 38.3969 26.4766 38.5308 27.5066 38.5308C29.1431 38.5308 30.6664 38.2607 32.036 37.7284C33.4094 37.1936 34.6013 36.4307 35.5761 35.4591C36.5509 34.4891 37.3177 33.292 37.8546 31.9039C38.3899 30.5207 38.6609 28.962 38.6609 27.2696C38.6609 25.5771 38.398 24.0289 37.8808 22.6615C37.3585 21.2876 36.6087 20.1078 35.6509 19.1534" fill="#264FF9"/>
    <path d="M46.5897 5.24123C47.5814 4.25229 47.5791 2.6512 46.585 1.66456C45.5911 0.677927 43.9817 0.680233 42.9899 1.66918C41.9981 2.65812 42.0005 4.25921 42.9946 5.24584C43.9885 6.23248 45.5979 6.23018 46.5897 5.24123ZM45.8306 3.45404L44.791 4.49068L43.7489 3.45635L44.7886 2.41973L45.8306 3.45404Z" fill="#FFC907"/>
    <path d="M40.7853 8.9702C41.972 7.78669 41.9696 5.87109 40.7798 4.69065C39.5903 3.51025 37.6648 3.51255 36.4781 4.69606C35.2917 5.87879 35.294 7.79517 36.4836 8.9756C37.6733 10.156 39.5988 10.1537 40.7853 8.9702ZM39.8771 6.8316L38.6332 8.072L37.3866 6.83468L38.6302 5.59425L39.8771 6.8316Z" fill="#FFC907"/>
    <path d="M47.5697 15.0131C49.0484 13.5381 49.0453 11.1503 47.5626 9.6792C46.0802 8.20809 43.6801 8.21116 42.2012 9.68613C40.7225 11.1611 40.7256 13.5489 42.2083 15.02C43.69 16.4911 46.0911 16.488 47.5697 15.0131ZM46.4373 12.3477L44.8874 13.8934L43.3336 12.3515L44.8836 10.8058L46.4373 12.3477Z" fill="#FFC907"/>
    <path d="M44.6859 19.3487V38.1341C44.6859 41.6592 41.8133 44.5168 38.2699 44.5168H14.3103C11.468 44.5168 9.16294 42.2244 9.16294 39.3962V14.2979C9.16294 13.6035 9.30363 12.9406 9.55641 12.3362C10.3317 10.4836 12.1707 9.17705 14.3103 9.17705H31.5804C32.0388 9.17705 32.4865 9.04556 32.8714 8.79794L34.2257 7.92665C34.647 7.65597 34.4537 7.00537 33.9519 7.00537H14.3103C12.7774 7.00537 11.3528 7.47601 10.1748 8.27963C10.0024 8.39729 9.83777 8.52418 9.67621 8.65567L4.50026 12.6322C1.52269 15.0015 0.629883 15.9889 0.629883 19.9247V41.6101C0.629883 46.1595 4.33715 49.8468 8.9094 49.8468H32.0968C33.0345 49.8468 33.922 49.6393 34.7197 49.2701H34.7204L42.3321 46.1348C42.3484 46.128 42.3644 46.121 42.3814 46.1148L42.4208 46.0986H42.4178C42.7812 45.9441 43.1305 45.7612 43.4614 45.552C45.5075 44.2568 46.8686 41.9823 46.8686 39.3962V17.6169C46.8686 17.1617 46.3161 16.934 45.992 17.2555L45.25 17.9938C44.8891 18.3529 44.6859 18.8404 44.6859 19.348" fill="#FF3F7A"/>
  </g>
  <defs>
    <clipPath id="clip0_4647_130972">
      <rect width="48.1481" height="49.0741" fill="white" transform="translate(0.629883 0.926025)"/>
    </clipPath>
  </defs>
</svg>
`
