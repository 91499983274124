export default `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
  <mask id="mask0_1863_3021" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
    <rect y="0.5" width="24" height="24" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_1863_3021)">
    <circle cx="11.7871" cy="12.4065" r="10" fill="#E40051"/>
    <path d="M6 13.5V11.5H18V13.5H6Z" fill="white"/>
  </g>
</svg>
`
