export default `
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <mask id="mask0_1891_11876" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
    <rect width="20" height="20" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_1891_11876)">
    <path d="M11.6667 18.3334L20 10.0001L11.6667 1.66675L10.1875 3.14591L17.0417 10.0001L10.1875 16.8542L11.6667 18.3334Z" fill="#E40051"/>
  </g>
</svg>
`
