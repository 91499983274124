import homeNavBarCategories from 'assets/icons/v2/home-nav-bar/categories.svg'
import homeNavBarCategoriesActive from 'assets/icons/v2/home-nav-bar/categories-active.svg'
import homeNavBarOrder from 'assets/icons/v2/home-nav-bar/order.svg'
import homeNavBarOrderActive from 'assets/icons/v2/home-nav-bar/order-active.svg'
import homeNavBarShop from 'assets/icons/v2/home-nav-bar/shop.svg'
import homeNavBarShopActive from 'assets/icons/v2/home-nav-bar/shop-active.svg'
import homeNavBarShopping from 'assets/icons/v2/home-nav-bar/shopping.svg'
import homeNavBarShoppingActive from 'assets/icons/v2/home-nav-bar/shopping-active.svg'
import homeNavBarUfu from 'assets/icons/v2/home-nav-bar/ufu.svg'

export const homeNavBarIcon = {
  homeNavBarCategories,
  homeNavBarCategoriesActive,
  homeNavBarOrder,
  homeNavBarOrderActive,
  homeNavBarShop,
  homeNavBarShopActive,
  homeNavBarShopping,
  homeNavBarShoppingActive,
  homeNavBarUfu,
}
