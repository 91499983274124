export default `
<svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="Group">
    <circle id="Ellipse 13" cx="6.05547" cy="17.8" r="5.2" fill="#CCDDEC"/>
    <ellipse id="Ellipse 14" cx="17.6554" cy="5.4" rx="2.4" ry="2.4" fill="#FFE066"/>
    <circle id="Ellipse 16" cx="21.6554" cy="15.8" r="2.4" fill="#F499B9"/>
    <path id="Vector" d="M10.8418 1.39999H4.25735C3.9917 1.4003 3.73702 1.50596 3.54918 1.6938C3.36134 1.88164 3.25567 2.13632 3.25537 2.40197V8.9864C3.25567 9.25205 3.36134 9.50673 3.54918 9.69458C3.73702 9.88242 3.9917 9.98808 4.25735 9.98838H10.8418C11.1074 9.98808 11.3621 9.88242 11.55 9.69458C11.7378 9.50673 11.8435 9.25205 11.8438 8.9864V2.40197C11.8435 2.13632 11.7378 1.88164 11.55 1.6938C11.3621 1.50596 11.1074 1.4003 10.8418 1.39999ZM11.2712 8.9864C11.2711 9.10026 11.2258 9.20941 11.1453 9.28991C11.0648 9.37042 10.9556 9.4157 10.8418 9.41582H4.25735C4.1435 9.4157 4.03435 9.37042 3.95384 9.28991C3.87334 9.20941 3.82805 9.10026 3.82793 8.9864V2.40197C3.82805 2.28812 3.87334 2.17897 3.95384 2.09846C4.03435 2.01796 4.1435 1.97268 4.25735 1.97255H10.8418C10.9556 1.97268 11.0648 2.01796 11.1453 2.09846C11.2258 2.17897 11.2711 2.28812 11.2712 2.40197V8.9864Z" fill="#E40051" stroke="#E40051"/>
    <path id="Vector_2" d="M20.7514 1.39999H14.167C13.9014 1.4003 13.6467 1.50596 13.4588 1.6938C13.271 1.88164 13.1653 2.13632 13.165 2.40197V8.9864C13.1653 9.25205 13.271 9.50673 13.4588 9.69458C13.6467 9.88242 13.9014 9.98808 14.167 9.98838H20.7514C21.0171 9.98808 21.2718 9.88242 21.4596 9.69458C21.6475 9.50673 21.7531 9.25205 21.7534 8.9864V2.40197C21.7531 2.13632 21.6475 1.88164 21.4596 1.6938C21.2718 1.50596 21.0171 1.4003 20.7514 1.39999ZM21.1809 8.9864C21.1807 9.10026 21.1355 9.20941 21.055 9.28991C20.9745 9.37042 20.8653 9.4157 20.7514 9.41582H14.167C14.0532 9.4157 13.944 9.37042 13.8635 9.28991C13.783 9.20941 13.7377 9.10026 13.7376 8.9864V2.40197C13.7377 2.28812 13.783 2.17897 13.8635 2.09846C13.944 2.01796 14.0532 1.97268 14.167 1.97255H20.7514C20.8653 1.97268 20.9745 2.01796 21.055 2.09846C21.1355 2.17897 21.1807 2.28812 21.1809 2.40197V8.9864Z" fill="#E40051" stroke="#E40051"/>
    <path id="Vector_3" d="M10.8418 11.3097H4.25735C3.9917 11.31 3.73702 11.4157 3.54918 11.6035C3.36134 11.7914 3.25567 12.0461 3.25537 12.3117V18.8961C3.25567 19.1618 3.36134 19.4165 3.54918 19.6043C3.73702 19.7922 3.9917 19.8978 4.25735 19.8981H10.8418C11.1074 19.8978 11.3621 19.7922 11.55 19.6043C11.7378 19.4165 11.8435 19.1618 11.8438 18.8961V12.3117C11.8435 12.0461 11.7378 11.7914 11.55 11.6035C11.3621 11.4157 11.1074 11.31 10.8418 11.3097ZM11.2712 18.8961C11.2711 19.01 11.2258 19.1192 11.1453 19.1997C11.0648 19.2802 10.9556 19.3254 10.8418 19.3256H4.25735C4.1435 19.3254 4.03435 19.2802 3.95384 19.1997C3.87334 19.1192 3.82805 19.01 3.82793 18.8961V12.3117C3.82805 12.1979 3.87334 12.0887 3.95384 12.0082C4.03435 11.9277 4.1435 11.8824 4.25735 11.8823H10.8418C10.9556 11.8824 11.0648 11.9277 11.1453 12.0082C11.2258 12.0887 11.2711 12.1979 11.2712 12.3117V18.8961Z" fill="#E40051" stroke="#E40051"/>
    <path id="Vector_4" d="M20.7689 13.7404C20.7594 13.6247 20.7071 13.5168 20.6225 13.4381C20.5379 13.3595 20.4271 13.3158 20.3122 13.3158H19.3955V12.6211C19.395 12.1913 19.2259 11.7792 18.9251 11.4753C18.6244 11.1714 18.2166 11.0005 17.7913 11H17.333C16.9077 11.0005 16.5 11.1714 16.1992 11.4753C15.8985 11.7792 15.7293 12.1913 15.7288 12.6211V13.3158H14.8122C14.6972 13.3158 14.5864 13.3595 14.5018 13.4381C14.4172 13.5168 14.365 13.6247 14.3554 13.7404L13.8971 19.2983C13.8918 19.3622 13.8997 19.4264 13.9203 19.487C13.9408 19.5476 13.9736 19.6033 14.0165 19.6504C14.0595 19.6976 14.1116 19.7352 14.1697 19.761C14.2277 19.7867 14.2904 19.8 14.3538 19.8H20.7705C20.8339 19.8 20.8966 19.7867 20.9547 19.761C21.0127 19.7352 21.0649 19.6976 21.1078 19.6504C21.1507 19.6033 21.1835 19.5476 21.2041 19.487C21.2246 19.4264 21.2325 19.3622 21.2273 19.2983L20.7689 13.7404ZM16.6455 12.6211C16.6457 12.4369 16.7182 12.2603 16.8471 12.13C16.976 11.9998 17.1507 11.9265 17.333 11.9263H17.7913C17.9736 11.9265 18.1484 11.9998 18.2773 12.13C18.4062 12.2603 18.4786 12.4369 18.4788 12.6211V13.3158H16.6455V12.6211ZM14.8519 18.8737L15.2339 14.2421H15.7288V14.7053C15.7288 14.8281 15.7771 14.9459 15.8631 15.0328C15.949 15.1196 16.0656 15.1684 16.1872 15.1684C16.3087 15.1684 16.4253 15.1196 16.5113 15.0328C16.5972 14.9459 16.6455 14.8281 16.6455 14.7053V14.2421H18.4788V14.7053C18.4788 14.8281 18.5271 14.9459 18.6131 15.0328C18.699 15.1196 18.8156 15.1684 18.9372 15.1684C19.0587 15.1684 19.1753 15.1196 19.2613 15.0328C19.3472 14.9459 19.3955 14.8281 19.3955 14.7053V14.2421H19.8905L20.2724 18.8737H14.8519Z" fill="#E40051" stroke="#E40051" stroke-width="0.2"/>
  </g>
</svg>
`
