export default `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <mask id="mask0_98_2259" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
    <rect width="24" height="24" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_98_2259)">
    <path d="M12 22L10.55 20.7C8.86667 19.1834 7.475 17.875 6.375 16.775C5.275 15.675 4.4 14.6875 3.75 13.8125C3.1 12.9375 2.64583 12.1334 2.3875 11.4C2.12917 10.6667 2 9.91669 2 9.15002C2 7.58336 2.525 6.27502 3.575 5.22502C4.625 4.17502 5.93333 3.65002 7.5 3.65002C8.36667 3.65002 9.19167 3.83336 9.975 4.20002C10.7583 4.56669 11.4333 5.08336 12 5.75002C12.5667 5.08336 13.2417 4.56669 14.025 4.20002C14.8083 3.83336 15.6333 3.65002 16.5 3.65002C18.0667 3.65002 19.375 4.17502 20.425 5.22502C21.475 6.27502 22 7.58336 22 9.15002C22 9.91669 21.8708 10.6667 21.6125 11.4C21.3542 12.1334 20.9 12.9375 20.25 13.8125C19.6 14.6875 18.725 15.675 17.625 16.775C16.525 17.875 15.1333 19.1834 13.45 20.7L12 22Z" fill="#E40051"/>
  </g>
</svg>
`
