import homeBuyByBrand from 'assets/icons/v2/home-shortcut/buy-by-brand.svg'
import homeCoupon from 'assets/icons/v2/home-shortcut/coupon.svg'
import homePopularProduct from 'assets/icons/v2/home-shortcut/popular-product.svg'
import homePromotionProduct from 'assets/icons/v2/home-shortcut/promotion-product.svg'
import homePurchasedProduct from 'assets/icons/v2/home-shortcut/purchased-product.svg'
import homeRepurchase from 'assets/icons/v2/home-shortcut/re-purchase.svg'
import homeUfu from 'assets/icons/v2/home-shortcut/ufu.svg'
import homeWishlistProduct from 'assets/icons/v2/home-shortcut/wishlist-product.svg'

export const homeShortcutIcon = {
  homeBuyByBrand,
  homeCoupon,
  homePopularProduct,
  homePromotionProduct,
  homePurchasedProduct,
  homeRepurchase,
  homeUfu,
  homeWishlistProduct,
}
