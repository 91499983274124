export type ButtonType = 'primary' | 'default' | 'text' | 'icon'
export type ButtonSize = 'large' | 'small'
export type ButtonColor = 'pink' | 'blue'

export const ButtonType = {
  Primary: 'primary' as ButtonType,
  Default: 'default' as ButtonType,
  Text: 'text' as ButtonType,
  Icon: 'icon' as ButtonType,
}

export const ButtonSize = {
  Large: 'large' as ButtonSize,
  Small: 'small' as ButtonSize,
}

export const ButtonColor = {
  Pink: 'pink' as ButtonColor,
  Blue: 'blue' as ButtonColor,
}
