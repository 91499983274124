// Please always name file with IconName-Color.svg with kebab case and order by alphabet
// [Fix this if have time] Loader problem with ng test when import .svg, use .svg.ts for the time being.
import addPhotoPink from 'assets/icons/v2/add-photo-pink.svg'
import alarmBlack from 'assets/icons/v2/alarm-black.svg'
import alarmRed from 'assets/icons/v2/alarm-red.svg'
import arrowBackBlack from 'assets/icons/v2/arrow-back-black.svg'
import arrowDownBlack from 'assets/icons/v2/arrow-down-black.svg'
import arrowDownBlue from 'assets/icons/v2/arrow-down-blue.svg'
import arrowRightBlue from 'assets/icons/v2/arrow-right-blue.svg'
import arrowRightGrey from 'assets/icons/v2/arrow-right-grey.svg'
import arrowRightPink from 'assets/icons/v2/arrow-right-pink.svg'
import arrowUpBlack from 'assets/icons/v2/arrow-up-black.svg'
import arrowUpBlue from 'assets/icons/v2/arrow-up-blue.svg'
import barcodeScannerBlue from 'assets/icons/v2/barcode-scanner-blue.svg'
import bogoPromotion from '../../../../../assets/icons/v2/bogo-promotion.svg'
import boxGrey from 'assets/icons/v2/box-grey.svg'
import buyWithDiscountPromotion from 'assets/icons/v2/buy-with-discount-promotion.svg'
import cartBlack from 'assets/icons/v2/cart-black.svg'
import cartGrey from 'assets/icons/v2/cart-grey.svg'
import cartPink from 'assets/icons/v2/cart-pink.svg'
import cartWhite from 'assets/icons/v2/cart-white.svg'
import categoriesGrey from 'assets/icons/v2/categories-grey.svg'
import checkCircleGreen from 'assets/icons/v2/check-circle-green.svg'
import checkWhite from 'assets/icons/v2/check-white.svg'
import chevronRightGrey from '../../../../../assets/icons/v2/chevron-right-grey.svg'
import closeBlack from 'assets/icons/v2/close-black.svg'
import closeCircleRed from 'assets/icons/v2/close-circle-red.svg'
import coupon from 'assets/icons/v2/coupon.svg'
import deleteBlack from 'assets/icons/v2/delete-black.svg'
import deleteCirclePink from 'assets/icons/v2/delete-circle-pink.svg'
import eCommerceBlack from 'assets/icons/v2/e-commerce-black.svg'
import findProductGrey from 'assets/icons/v2/find-product-grey.svg'
import flashOffBlack from 'assets/icons/v2/flash-off-black.svg'
import flashOnBlack from 'assets/icons/v2/flash-on-black.svg'
import deleteBlue from 'assets/icons/v2/delete-blue.svg'
import flashSale from 'assets/icons/v2/flash-sale.svg'
import flatPromotion from 'assets/icons/v2/flat-promotion.svg'
import flipCameraBlack from 'assets/icons/v2/flip-camera-black.svg'
import heartFilledPink from 'assets/icons/v2/heart-filled-pink.svg'
import heartOutlineBlack from 'assets/icons/v2/heart-outline-black.svg'
import minusBlue from 'assets/icons/v2/minus-blue.svg'
import minusCirclePink from 'assets/icons/v2/minus-circle-pink.svg'
import plusBlue from 'assets/icons/v2/plus-blue.svg'
import plusCirclePink from 'assets/icons/v2/plus-circle-pink.svg'
import progressivePromotion from 'assets/icons/v2/progressive-promotion.svg'
import qrScannerPink from 'assets/icons/v2/qr-scanner-pink.svg'
import repurchaseGrey from 'assets/icons/v2/repurchase-grey.svg'
import ruammitrUfu from 'assets/icons/v2/ruammitr-ufu.svg'
import searchBlack from 'assets/icons/v2/search-black.svg'
import searchBlack60 from 'assets/icons/v2/search-black-60.svg'
import searchCrossGrey from 'assets/icons/v2/search-cross-grey.svg'
import searchGrey from 'assets/icons/v2/search-grey.svg'
import verticalAlignTopBlue from 'assets/icons/v2/vertical-align-top-blue.svg'
import { homeShortcutIcon } from '@shared/components/v2/icon/home-shortcut-icon'
import { homeNavBarIcon } from '@shared/components/v2/icon/home-nav-bar-icon'

export const IconList = {
  addPhotoPink,
  alarmBlack,
  alarmRed,
  arrowBackBlack,
  arrowDownBlack,
  arrowDownBlue,
  arrowRightBlue,
  arrowRightGrey,
  arrowRightPink,
  arrowUpBlack,
  arrowUpBlue,
  barcodeScannerBlue,
  bogoPromotion,
  boxGrey,
  buyWithDiscountPromotion,
  cartBlack,
  cartGrey,
  cartPink,
  cartWhite,
  categoriesGrey,
  checkCircleGreen,
  checkWhite,
  chevronRightGrey,
  closeBlack,
  closeCircleRed,
  coupon,
  deleteBlack,
  deleteBlue,
  deleteCirclePink,
  eCommerceBlack,
  findProductGrey,
  flashOffBlack,
  flashOnBlack,
  flashSale,
  flatPromotion,
  flipCameraBlack,
  heartFilledPink,
  heartOutlineBlack,
  minusBlue,
  minusCirclePink,
  plusBlue,
  plusCirclePink,
  progressivePromotion,
  qrScannerPink,
  repurchaseGrey,
  ruammitrUfu,
  searchBlack,
  searchBlack60,
  searchCrossGrey,
  searchGrey,
  verticalAlignTopBlue,
  ...homeShortcutIcon,
  ...homeNavBarIcon,
}
