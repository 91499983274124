export default `
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <mask id="mask0_835_48334" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
    <rect width="20" height="20" fill="#D9D9D9" />
  </mask>
  <g mask="url(#mask0_835_48334)">
    <path
      d="M10 17.9999C9.02782 17.9999 8.11717 17.8152 7.2681 17.4458C6.41902 17.0763 5.68013 16.5772 5.05144 15.9485C4.42273 15.3198 3.92365 14.5809 3.55421 13.7319C3.18476 12.8828 3.00004 11.9721 3.00004 10.9999C3.00004 10.0277 3.18476 9.11705 3.55421 8.26798C3.92365 7.4189 4.42273 6.68001 5.05144 6.05131C5.68013 5.42261 6.41902 4.92353 7.2681 4.55408C8.11717 4.18464 9.02782 3.99992 10 3.99992C10.9723 3.99992 11.8829 4.18464 12.732 4.55408C13.5811 4.92353 14.32 5.42261 14.9486 6.05131C15.5774 6.68001 16.0764 7.4189 16.4459 8.26798C16.8153 9.11705 17 10.0277 17 10.9999C17 11.9721 16.8153 12.8828 16.4459 13.7319C16.0764 14.5809 15.5774 15.3198 14.9486 15.9485C14.32 16.5772 13.5811 17.0763 12.732 17.4458C11.8829 17.8152 10.9723 17.9999 10 17.9999ZM12.0834 13.8333L13.1459 12.7708L10.75 10.3749V6.99992H9.25004V10.9999L12.0834 13.8333ZM4.93754 2.39575L6.00004 3.45825L2.45837 6.99992L1.39587 5.93742L4.93754 2.39575ZM15.0625 2.39575L18.6042 5.93742L17.5417 6.99992L14 3.45825L15.0625 2.39575ZM9.99542 16.4999C11.5263 16.4999 12.8264 15.9667 13.8959 14.9004C14.9653 13.834 15.5 12.5354 15.5 11.0045C15.5 9.47368 14.9669 8.17353 13.9005 7.10409C12.8341 6.03464 11.5355 5.49992 10.0047 5.49992C8.4738 5.49992 7.17365 6.0331 6.10421 7.09946C5.03476 8.16584 4.50004 9.46445 4.50004 10.9953C4.50004 12.5262 5.03322 13.8263 6.09958 14.8958C7.16596 15.9652 8.46457 16.4999 9.99542 16.4999Z"
      fill="#FF0000" />
  </g>
</svg>
`
