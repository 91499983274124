export default `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <mask id="mask0_857_53482" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
    <rect width="24" height="24" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_857_53482)">
    <path d="M12 15.6L15.2 11H12.35L14.35 4H9V12H12V15.6ZM10 22V14H7V2H17L15 9H19L10 22Z" fill="black"/>
  </g>
</svg>
`
