export default `
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
  <mask id="mask0_1443_39923" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
    <rect y="0.5" width="16" height="16" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_1443_39923)">
    <path d="M8.39967 8.5L5.33301 5.43333L6.26634 4.5L10.2663 8.5L6.26634 12.5L5.33301 11.5667L8.39967 8.5Z" fill="#8F8F8F"/>
  </g>
</svg>
`
