export default `
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
  <mask id="mask0_1342_36730" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="20">
    <rect x="0.5" width="20" height="20" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_1342_36730)">
    <path d="M2.16675 5.83342V1.66675H6.33342V3.33341H3.83341V5.83342H2.16675ZM2.16675 18.3334V14.1667H3.83341V16.6667H6.33342V18.3334H2.16675ZM14.6667 18.3334V16.6667H17.1667V14.1667H18.8334V18.3334H14.6667ZM17.1667 5.83342V3.33341H14.6667V1.66675H18.8334V5.83342H17.1667ZM15.0834 14.5834H16.3334V15.8334H15.0834V14.5834ZM15.0834 12.0834H16.3334V13.3334H15.0834V12.0834ZM13.8334 13.3334H15.0834V14.5834H13.8334V13.3334ZM12.5834 14.5834H13.8334V15.8334H12.5834V14.5834ZM11.3334 13.3334H12.5834V14.5834H11.3334V13.3334ZM13.8334 10.8334H15.0834V12.0834H13.8334V10.8334ZM12.5834 12.0834H13.8334V13.3334H12.5834V12.0834ZM11.3334 10.8334H12.5834V12.0834H11.3334V10.8334ZM16.3334 4.16675V9.16675H11.3334V4.16675H16.3334ZM9.66675 10.8334V15.8334H4.66675V10.8334H9.66675ZM9.66675 4.16675V9.16675H4.66675V4.16675H9.66675ZM8.41675 14.5834V12.0834H5.91675V14.5834H8.41675ZM8.41675 7.91675V5.41675H5.91675V7.91675H8.41675ZM15.0834 7.91675V5.41675H12.5834V7.91675H15.0834Z" fill="#E40051"/>
  </g>
</svg>
`
