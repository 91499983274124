export default `
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <mask id="mask0_1322_36901" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
    <rect width="20" height="20" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_1322_36901)">
    <path d="M5.52778 8.33333L4.16667 9.59459L10 15L15.8333 9.59459L14.4722 8.33333L10 12.455L5.52778 8.33333Z" fill="black"/>
  </g>
</svg>
`
