export default `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <mask id="mask0_1861_20248" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
    <rect width="24" height="24" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_1861_20248)">
    <circle cx="12" cy="12" r="10" fill="#E40051"/>
    <path d="M8.875 18C8.53125 18 8.23698 17.8694 7.99219 17.6083C7.7474 17.3472 7.625 17.0333 7.625 16.6667V8H7V6.66667H10.125V6H13.875V6.66667H17V8H16.375V16.6667C16.375 17.0333 16.2526 17.3472 16.0078 17.6083C15.763 17.8694 15.4688 18 15.125 18H8.875ZM15.125 8H8.875V16.6667H15.125V8ZM10.125 15.3333H11.375V9.33333H10.125V15.3333ZM12.625 15.3333H13.875V9.33333H12.625V15.3333Z" fill="white"/>
  </g>
</svg>
`
