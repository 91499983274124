export default `
<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
  <mask id="mask0_3371_22822" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="60" height="60">
    <rect width="60" height="60" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_3371_22822)">
    <path d="M21 42.5L30 33.5L39 42.5L42.5 39L33.5 30L42.5 21L39 17.5L30 26.5L21 17.5L17.5 21L26.5 30L17.5 39L21 42.5ZM30 55C26.5417 55 23.2917 54.3438 20.25 53.0312C17.2083 51.7188 14.5625 49.9375 12.3125 47.6875C10.0625 45.4375 8.28125 42.7917 6.96875 39.75C5.65625 36.7083 5 33.4583 5 30C5 26.5417 5.65625 23.2917 6.96875 20.25C8.28125 17.2083 10.0625 14.5625 12.3125 12.3125C14.5625 10.0625 17.2083 8.28125 20.25 6.96875C23.2917 5.65625 26.5417 5 30 5C33.4583 5 36.7083 5.65625 39.75 6.96875C42.7917 8.28125 45.4375 10.0625 47.6875 12.3125C49.9375 14.5625 51.7188 17.2083 53.0312 20.25C54.3438 23.2917 55 26.5417 55 30C55 33.4583 54.3438 36.7083 53.0312 39.75C51.7188 42.7917 49.9375 45.4375 47.6875 47.6875C45.4375 49.9375 42.7917 51.7188 39.75 53.0312C36.7083 54.3438 33.4583 55 30 55Z" fill="#FF0000"/>
  </g>
</svg>
`
