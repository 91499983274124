export default `
<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
  <path d="M25.0002 48.455C37.5521 48.455 47.7275 38.2797 47.7275 25.7278C47.7275 13.1758 37.5521 3.00049 25.0002 3.00049C12.4483 3.00049 2.27295 13.1758 2.27295 25.7278C2.27295 38.2797 12.4483 48.455 25.0002 48.455Z" fill="url(#paint0_radial_4616_123706)"/>
  <path d="M39.8151 21.2964C45.4402 21.2964 50.0003 16.7363 50.0003 11.1112C50.0003 5.48609 45.4402 0.926025 39.8151 0.926025C34.1899 0.926025 29.6299 5.48609 29.6299 11.1112C29.6299 16.7363 34.1899 21.2964 39.8151 21.2964Z" fill="#FF005B"/>
  <path d="M46.0981 9.25928H33.532C33.1667 9.25928 32.8706 9.67383 32.8706 10.1852V11.1111C32.8706 11.6225 33.1667 12.0371 33.532 12.0371H46.0981C46.4634 12.0371 46.7595 11.6225 46.7595 11.1111V10.1852C46.7595 9.67383 46.4634 9.25928 46.0981 9.25928Z" fill="#FFACC9"/>
  <path d="M43.6801 17.5927H35.9497C35.6295 17.5922 35.3201 17.4964 35.0778 17.3226C34.8354 17.1488 34.6762 16.9085 34.6289 16.6454L33.7964 12.0371H45.8334L45.0009 16.6454C44.9536 16.9085 44.7944 17.1488 44.552 17.3226C44.3097 17.4964 44.0003 17.5922 43.6801 17.5927Z" fill="white"/>
  <path d="M39.8151 4.62964C40.0607 4.62964 40.2962 4.73416 40.4698 4.92021C40.6435 5.10626 40.741 5.35859 40.741 5.6217V9.25927H38.8892V5.6217C38.8892 5.35859 38.9867 5.10626 39.1604 4.92021C39.334 4.73416 39.5695 4.62964 39.8151 4.62964Z" fill="white"/>
  <path d="M40.3709 13.492C40.3709 13.1998 40.1221 12.9629 39.8153 12.9629C39.5085 12.9629 39.2598 13.1998 39.2598 13.492V16.1375C39.2598 16.4297 39.5085 16.6666 39.8153 16.6666C40.1221 16.6666 40.3709 16.4297 40.3709 16.1375V13.492Z" fill="#FF005B"/>
  <path d="M43.1487 13.492C43.1487 13.1998 42.9 12.9629 42.5932 12.9629C42.2863 12.9629 42.0376 13.1998 42.0376 13.492V16.1375C42.0376 16.4297 42.2863 16.6666 42.5932 16.6666C42.9 16.6666 43.1487 16.4297 43.1487 16.1375V13.492Z" fill="#FF005B"/>
  <path d="M37.593 13.492C37.593 13.1998 37.3443 12.9629 37.0375 12.9629C36.7307 12.9629 36.4819 13.1998 36.4819 13.492V16.1375C36.4819 16.4297 36.7307 16.6666 37.0375 16.6666C37.3443 16.6666 37.593 16.4297 37.593 16.1375V13.492Z" fill="#FF005B"/>
  <path d="M42.7274 43.182V45.0002C42.7274 45.2413 42.6316 45.4726 42.4611 45.643C42.2906 45.8135 42.0594 45.9093 41.8183 45.9093H16.0865C13.9601 45.0045 11.9855 43.7781 10.2319 42.2729H41.8183C42.0594 42.2729 42.2906 42.3687 42.4611 42.5392C42.6316 42.7097 42.7274 42.9409 42.7274 43.182Z" fill="#99BBD9"/>
  <path d="M3.63672 8.182C3.63672 8.54365 3.78039 8.8905 4.03612 9.14623C4.29185 9.40196 4.6387 9.54563 5.00036 9.54563H10.0004V42.0684L10.2049 42.2729H12.7276V8.63654C12.7276 8.15433 12.5361 7.69187 12.1951 7.35089C11.8541 7.00992 11.3917 6.81836 10.9094 6.81836H5.00036C4.6387 6.81836 4.29185 6.96203 4.03612 7.21776C3.78039 7.47349 3.63672 7.82034 3.63672 8.182Z" fill="#99BBD9"/>
  <path d="M38.1821 25H14.5457C13.5416 25 12.7275 25.814 12.7275 26.8182V40.4545C12.7275 41.4587 13.5416 42.2727 14.5457 42.2727H38.1821C39.1862 42.2727 40.0003 41.4587 40.0003 40.4545V26.8182C40.0003 25.814 39.1862 25 38.1821 25Z" fill="#0055A1"/>
  <path d="M34.4911 38.85C34.4524 38.9226 34.3948 38.9833 34.3244 39.0258C34.254 39.0682 34.1733 39.0907 34.0911 39.0909H33.6365V40C33.6365 40.1206 33.5887 40.2362 33.5034 40.3214C33.4182 40.4067 33.3026 40.4546 33.182 40.4546C33.0614 40.4546 32.9458 40.4067 32.8606 40.3214C32.7753 40.2362 32.7275 40.1206 32.7275 40V39.0909H32.2729C32.1907 39.0907 32.1101 39.0682 32.0396 39.0258C31.9692 38.9833 31.9116 38.9226 31.8729 38.85C31.833 38.7809 31.812 38.7025 31.812 38.6227C31.812 38.5429 31.833 38.4646 31.8729 38.3955L32.782 37.0318C32.8236 36.97 32.8797 36.9193 32.9455 36.8842C33.0113 36.8491 33.0847 36.8308 33.1593 36.8308C33.2338 36.8308 33.3072 36.8491 33.373 36.8842C33.4388 36.9193 33.495 36.97 33.5365 37.0318L34.4456 38.3955C34.4927 38.4605 34.5216 38.5368 34.5296 38.6166C34.5376 38.6964 34.5243 38.777 34.4911 38.85Z" fill="white"/>
  <path d="M38.1273 38.85C38.0887 38.9226 38.0311 38.9833 37.9606 39.0258C37.8902 39.0682 37.8096 39.0907 37.7273 39.0909H37.2728V40C37.2728 40.1206 37.2249 40.2362 37.1396 40.3214C37.0544 40.4067 36.9388 40.4546 36.8182 40.4546C36.6977 40.4546 36.5821 40.4067 36.4968 40.3214C36.4116 40.2362 36.3637 40.1206 36.3637 40V39.0909H35.9091C35.8269 39.0907 35.7463 39.0682 35.6758 39.0258C35.6054 38.9833 35.5478 38.9226 35.5091 38.85C35.4692 38.7809 35.4482 38.7025 35.4482 38.6227C35.4482 38.5429 35.4692 38.4646 35.5091 38.3955L36.4182 37.0318C36.4598 36.97 36.516 36.9193 36.5818 36.8842C36.6476 36.8491 36.721 36.8308 36.7955 36.8308C36.8701 36.8308 36.9435 36.8491 37.0092 36.8842C37.075 36.9193 37.1312 36.97 37.1728 37.0318L38.0819 38.3955C38.1289 38.4605 38.1579 38.5368 38.1658 38.6166C38.1738 38.6964 38.1605 38.777 38.1273 38.85Z" fill="white"/>
  <path d="M25.9094 11.3638H14.0912C13.3381 11.3638 12.7275 11.9743 12.7275 12.7274V23.6365C12.7275 24.3896 13.3381 25.0001 14.0912 25.0001H25.9094C26.6625 25.0001 27.273 24.3896 27.273 23.6365V12.7274C27.273 11.9743 26.6625 11.3638 25.9094 11.3638Z" fill="#0055A1"/>
  <path d="M29.5458 30.9091C29.5455 30.9913 29.523 31.0719 29.4806 31.1424C29.4382 31.2128 29.3774 31.2704 29.3049 31.3091C29.2358 31.349 29.1574 31.37 29.0776 31.37C28.9978 31.37 28.9194 31.349 28.8503 31.3091L27.7276 30.5455L26.6139 31.2864C26.5397 31.3352 26.4528 31.3613 26.3639 31.3613C26.2751 31.3613 26.1882 31.3352 26.1139 31.2864L25.0003 30.5455L23.8867 31.2864C23.8176 31.3263 23.7392 31.3473 23.6594 31.3473C23.5796 31.3473 23.5012 31.3263 23.4321 31.2864C23.3611 31.2508 23.3007 31.1972 23.2568 31.1311C23.213 31.0649 23.1872 30.9883 23.1821 30.9091V25H29.5458V30.9091Z" fill="#99BBD9"/>
  <path d="M19.0914 36.8184H15.455C14.9529 36.8184 14.5459 37.2254 14.5459 37.7275V39.5456C14.5459 40.0477 14.9529 40.4547 15.455 40.4547H19.0914C19.5934 40.4547 20.0004 40.0477 20.0004 39.5456V37.7275C20.0004 37.2254 19.5934 36.8184 19.0914 36.8184Z" fill="#99BBD9"/>
  <path d="M22.273 15.4547C22.2725 15.5444 22.2455 15.632 22.1954 15.7064C22.1452 15.7809 22.0742 15.8388 21.9912 15.8729C21.9372 15.8983 21.8781 15.9108 21.8184 15.9092C21.7586 15.9096 21.6993 15.8981 21.6439 15.8755C21.5886 15.8529 21.5382 15.8195 21.4957 15.7774L20.9094 15.1865L20.323 15.7774C20.2807 15.82 20.2305 15.8538 20.1751 15.8769C20.1197 15.9 20.0603 15.9119 20.0003 15.9119C19.9403 15.9119 19.8808 15.9 19.8255 15.8769C19.7701 15.8538 19.7198 15.82 19.6775 15.7774L19.0912 15.1865L18.5048 15.7774C18.4409 15.8405 18.3597 15.8832 18.2715 15.9002C18.1834 15.9172 18.0921 15.9077 18.0094 15.8729C17.9263 15.8388 17.8553 15.7809 17.8051 15.7064C17.755 15.632 17.728 15.5444 17.7275 15.4547V11.3638H22.273V15.4547Z" fill="#99BBD9"/>
  <path d="M17.7277 20.4546H15.0004C14.7494 20.4546 14.5459 20.6581 14.5459 20.9091V22.7273C14.5459 22.9784 14.7494 23.1819 15.0004 23.1819H17.7277C17.9788 23.1819 18.1823 22.9784 18.1823 22.7273V20.9091C18.1823 20.6581 17.9788 20.4546 17.7277 20.4546Z" fill="#99BBD9"/>
  <defs>
    <radialGradient id="paint0_radial_4616_123706" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.0002 25.7278) rotate(90) scale(22.7273)">
      <stop offset="0.545" stop-color="white"/>
      <stop offset="1" stop-color="#FFFDC0"/>
    </radialGradient>
  </defs>
</svg>
`
