export default `
<svg xmlns="http://www.w3.org/2000/svg" width="51" height="50" viewBox="0 0 51 50" fill="none">
  <g clip-path="url(#clip0_4616_123732)">
    <path d="M23.8484 50.4545C36.4003 50.4545 46.5756 40.2792 46.5756 27.7273C46.5756 15.1753 36.4003 5 23.8484 5C11.2964 5 1.12109 15.1753 1.12109 27.7273C1.12109 40.2792 11.2964 50.4545 23.8484 50.4545Z" fill="url(#paint0_radial_4616_123732)"/>
    <path d="M16.1211 19.5454V45H10.212C7.38676 42.8854 5.09386 40.1409 3.51568 36.9845C1.9375 33.8281 1.11757 30.3471 1.12111 26.8181C1.12179 24.3454 1.52399 21.8892 2.31201 19.5454H16.1211Z" fill="#3377B4"/>
    <path d="M16.1211 19.5454H41.5756C42.0578 19.5454 42.5203 19.737 42.8613 20.0779C43.2023 20.4189 43.3938 20.8814 43.3938 21.3636V43.1818C43.3938 43.664 43.2023 44.1264 42.8613 44.4674C42.5203 44.8084 42.0578 45 41.5756 45H16.1211V19.5454Z" fill="#6699C7"/>
    <path d="M26.1209 22.2727H19.7572C19.2552 22.2727 18.8481 22.6797 18.8481 23.1818V25.9091C18.8481 26.4111 19.2552 26.8182 19.7572 26.8182H26.1209C26.6229 26.8182 27.03 26.4111 27.03 25.9091V23.1818C27.03 22.6797 26.6229 22.2727 26.1209 22.2727Z" fill="white"/>
    <path d="M23.8481 41.3635H19.3027C19.0517 41.3635 18.8481 41.567 18.8481 41.8181C18.8481 42.0691 19.0517 42.2726 19.3027 42.2726H23.8481C24.0992 42.2726 24.3027 42.0691 24.3027 41.8181C24.3027 41.567 24.0992 41.3635 23.8481 41.3635Z" fill="white"/>
    <path d="M23.8481 38.6362H19.3027C19.0517 38.6362 18.8481 38.8397 18.8481 39.0908C18.8481 39.3418 19.0517 39.5453 19.3027 39.5453H23.8481C24.0992 39.5453 24.3027 39.3418 24.3027 39.0908C24.3027 38.8397 24.0992 38.6362 23.8481 38.6362Z" fill="white"/>
    <path d="M16.121 19.5454V23.1818L12.0301 25.65C11.7473 25.8197 11.4236 25.9092 11.0938 25.909H4.8074C4.47755 25.9092 4.15387 25.8197 3.87103 25.65L1.28467 24.0909C1.46887 22.5442 1.81314 21.0209 2.31194 19.5454H16.121Z" fill="#0055A1"/>
    <path d="M17.3392 41.7339V45.3703H11.4301C9.12207 43.6345 7.16596 41.4748 5.6665 39.0066C5.74198 38.9975 5.8183 38.9975 5.89378 39.0066H12.4165C12.6632 39.0073 12.9051 39.0748 13.1165 39.2021L17.3392 41.7339Z" fill="#0055A1"/>
    <path d="M9.30287 24.0909V27.7273C9.30253 27.7994 9.28505 27.8704 9.25185 27.9344C9.21866 27.9984 9.1707 28.0536 9.11196 28.0955C9.03613 28.1528 8.94336 28.1832 8.84832 28.1818C8.80006 28.1884 8.75113 28.1884 8.70287 28.1818L7.48469 27.75L6.2665 28.1591C6.19815 28.1816 6.12541 28.1876 6.05429 28.1766C5.98317 28.1655 5.91569 28.1377 5.85741 28.0955C5.79867 28.0536 5.75072 27.9984 5.71752 27.9344C5.68432 27.8704 5.66684 27.7994 5.6665 27.7273V24.0909C5.66684 24.0188 5.68432 23.9478 5.71752 23.8838C5.75072 23.8198 5.79867 23.7646 5.85741 23.7227C5.91569 23.6805 5.98317 23.6527 6.05429 23.6416C6.12541 23.6306 6.19815 23.6366 6.2665 23.6591L7.48469 24.0909L8.70287 23.6818C8.77122 23.6593 8.84396 23.6533 8.91508 23.6644C8.9862 23.6754 9.05368 23.7032 9.11196 23.7455C9.1677 23.785 9.21381 23.8366 9.24687 23.8964C9.27992 23.9562 9.29906 24.0227 9.30287 24.0909Z" fill="#A5BCED"/>
    <path d="M9.30287 36.8182V40.4546C9.30253 40.5267 9.28505 40.5977 9.25185 40.6617C9.21866 40.7257 9.1707 40.7809 9.11196 40.8228C9.03613 40.8801 8.94336 40.9105 8.84832 40.9091C8.80006 40.9157 8.75113 40.9157 8.70287 40.9091L7.48469 40.4773L6.2665 40.8864C6.18055 40.9124 6.08882 40.9124 6.00287 40.8864C5.88923 40.7455 5.78014 40.6 5.6665 40.4318V36.8182C5.66684 36.7461 5.68432 36.6751 5.71752 36.6111C5.75072 36.5471 5.79867 36.4919 5.85741 36.45C5.91569 36.4078 5.98317 36.38 6.05429 36.3689C6.12541 36.3579 6.19815 36.3639 6.2665 36.3864L7.48469 36.8182L8.70287 36.4091C8.77122 36.3866 8.84396 36.3806 8.91508 36.3917C8.9862 36.4027 9.05368 36.4305 9.11196 36.4728C9.1677 36.5123 9.21381 36.5639 9.24687 36.6237C9.27992 36.6835 9.29906 36.75 9.30287 36.8182Z" fill="#A5BCED"/>
    <path d="M39.0926 27.7778C45.4848 27.7778 50.6667 22.5959 50.6667 16.2037C50.6667 9.81153 45.4848 4.62964 39.0926 4.62964C32.7004 4.62964 27.5186 9.81153 27.5186 16.2037C27.5186 22.5959 32.7004 27.7778 39.0926 27.7778Z" fill="#FF005B"/>
    <path d="M46.0342 13.7555L45.1666 20.549C45.141 20.7561 45.0385 20.9468 44.8784 21.0846C44.7184 21.2224 44.5122 21.2978 44.299 21.2962H33.8879C33.6747 21.2978 33.4685 21.2224 33.3084 21.0846C33.1484 20.9468 33.0458 20.7561 33.0203 20.549L32.1527 13.7555C32.1342 13.5832 32.17 13.4095 32.2553 13.2577C32.3406 13.1059 32.4713 12.9833 32.6299 12.9063C32.7925 12.8277 32.9763 12.801 33.1552 12.83C33.3342 12.8589 33.4994 12.942 33.6276 13.0676L36.2304 15.6152L38.2953 11.5731C38.3681 11.434 38.4787 11.3172 38.6149 11.2357C38.7512 11.1542 38.9077 11.1111 39.0674 11.1111C39.2271 11.1111 39.3837 11.1542 39.5199 11.2357C39.6561 11.3172 39.7668 11.434 39.8396 11.5731L41.9044 15.6152L44.5072 13.0676C44.6354 12.942 44.8006 12.8589 44.9796 12.83C45.1586 12.801 45.3423 12.8277 45.505 12.9063C45.6732 12.9754 45.8149 13.0947 45.9099 13.2471C46.0049 13.3996 46.0484 13.5775 46.0342 13.7555Z" fill="white"/>
    <path d="M26.449 10.5759C26.4488 10.6598 26.4253 10.742 26.3812 10.8135C26.337 10.8849 26.274 10.9427 26.199 10.9804C25.6748 11.2413 25.2073 11.6034 24.8234 12.0457C24.4396 12.488 24.1471 13.0019 23.9627 13.5577L23.7218 14.3577C23.6919 14.4486 23.634 14.5278 23.5565 14.584C23.479 14.6401 23.3857 14.6703 23.2899 14.6703C23.1942 14.6703 23.1009 14.6401 23.0234 14.584C22.9459 14.5278 22.888 14.4486 22.8581 14.3577L22.5945 13.5577C22.4101 13.0019 22.1175 12.488 21.7337 12.0457C21.3499 11.6034 20.8824 11.2413 20.3581 10.9804C20.2837 10.9423 20.2212 10.8843 20.1776 10.8129C20.1339 10.7416 20.1108 10.6595 20.1108 10.5759C20.1108 10.4922 20.1339 10.4102 20.1776 10.3388C20.2212 10.2674 20.2837 10.2095 20.3581 10.1713C20.8824 9.91042 21.3499 9.54838 21.7337 9.10606C22.1175 8.66374 22.4101 8.14989 22.5945 7.59406L22.8581 6.79406C22.888 6.70312 22.9459 6.62394 23.0234 6.5678C23.1009 6.51167 23.1942 6.48145 23.2899 6.48145C23.3857 6.48145 23.479 6.51167 23.5565 6.5678C23.634 6.62394 23.6919 6.70312 23.7218 6.79406L23.9854 7.59406C24.1698 8.14989 24.4624 8.66374 24.8462 9.10606C25.23 9.54838 25.6975 9.91042 26.2218 10.1713C26.2925 10.2121 26.3509 10.2712 26.3909 10.3424C26.4309 10.4136 26.451 10.4943 26.449 10.5759Z" fill="#FFCB00"/>
    <path d="M49.296 31.7778C49.2959 31.8347 49.2787 31.8905 49.2465 31.939C49.2142 31.9874 49.1682 32.0266 49.1134 32.0522C48.7305 32.2292 48.389 32.4749 48.1087 32.7749C47.8283 33.075 47.6147 33.4236 47.48 33.8007L47.304 34.3435C47.2822 34.4052 47.2399 34.4589 47.1833 34.497C47.1266 34.5351 47.0585 34.5556 46.9886 34.5556C46.9187 34.5556 46.8505 34.5351 46.7939 34.497C46.7373 34.4589 46.695 34.4052 46.6732 34.3435L46.4806 33.8007C46.3459 33.4236 46.1322 33.075 45.8519 32.7749C45.5716 32.4749 45.2301 32.2292 44.8471 32.0522C44.7927 32.0264 44.7471 31.987 44.7152 31.9386C44.6834 31.8902 44.6665 31.8345 44.6665 31.7778C44.6665 31.721 44.6834 31.6654 44.7152 31.6169C44.7471 31.5685 44.7927 31.5292 44.8471 31.5033C45.2301 31.3263 45.5716 31.0807 45.8519 30.7806C46.1322 30.4805 46.3459 30.1319 46.4806 29.7548L46.6732 29.2121C46.695 29.1504 46.7373 29.0967 46.7939 29.0586C46.8505 29.0205 46.9187 29 46.9886 29C47.0585 29 47.1266 29.0205 47.1833 29.0586C47.2399 29.0967 47.2822 29.1504 47.304 29.2121L47.4966 29.7548C47.6313 30.1319 47.8449 30.4805 48.1253 30.7806C48.4056 31.0807 48.7471 31.3263 49.13 31.5033C49.1817 31.531 49.2244 31.5711 49.2536 31.6194C49.2828 31.6677 49.2975 31.7224 49.296 31.7778Z" fill="#FFCB00"/>
  </g>
  <defs>
    <radialGradient id="paint0_radial_4616_123732" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.8484 27.7273) rotate(90) scale(22.7273)">
      <stop offset="0.545" stop-color="white"/>
      <stop offset="1" stop-color="#FFFDC0"/>
    </radialGradient>
    <clipPath id="clip0_4616_123732">
      <rect width="50" height="50" fill="white" transform="translate(0.666504)"/>
    </clipPath>
  </defs>
</svg>
`
