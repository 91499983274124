export default `
<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
  <path d="M23.1819 48.4548C35.7338 48.4548 45.9091 38.2794 45.9091 25.7275C45.9091 13.1756 35.7338 3.00024 23.1819 3.00024C10.6299 3.00024 0.45459 13.1756 0.45459 25.7275C0.45459 38.2794 10.6299 48.4548 23.1819 48.4548Z" fill="url(#paint0_radial_4616_123649)"/>
  <path d="M38.1819 6.81806C38.1817 6.902 38.1582 6.98423 38.1141 7.05564C38.0699 7.12704 38.0069 7.18484 37.9319 7.22261C37.4077 7.48353 36.9402 7.84556 36.5564 8.28788C36.1725 8.7302 35.88 9.24406 35.6956 9.79988L35.4547 10.5999C35.4248 10.6908 35.3669 10.77 35.2894 10.8261C35.2119 10.8823 35.1186 10.9125 35.0229 10.9125C34.9271 10.9125 34.8338 10.8823 34.7563 10.8261C34.6788 10.77 34.6209 10.6908 34.591 10.5999L34.3274 9.79988C34.143 9.24406 33.8504 8.7302 33.4666 8.28788C33.0828 7.84556 32.6153 7.48353 32.091 7.22261C32.0166 7.18447 31.9541 7.12651 31.9105 7.05513C31.8668 6.98376 31.8438 6.90172 31.8438 6.81806C31.8438 6.73441 31.8668 6.65237 31.9105 6.58099C31.9541 6.50962 32.0166 6.45166 32.091 6.41352C32.6153 6.1526 33.0828 5.79057 33.4666 5.34825C33.8504 4.90593 34.143 4.39207 34.3274 3.83625L34.591 3.03625C34.6209 2.94531 34.6788 2.86612 34.7563 2.80999C34.8338 2.75385 34.9271 2.72363 35.0229 2.72363C35.1186 2.72363 35.2119 2.75385 35.2894 2.80999C35.3669 2.86612 35.4248 2.94531 35.4547 3.03625L35.7183 3.83625C35.9027 4.39207 36.1953 4.90593 36.5791 5.34825C36.9629 5.79057 37.4304 6.1526 37.9547 6.41352C38.0254 6.45432 38.0838 6.51342 38.1238 6.58461C38.1638 6.65579 38.1839 6.73644 38.1819 6.81806Z" fill="#FFCB00"/>
  <path d="M14.5457 44.5454C14.5455 44.6293 14.522 44.7115 14.4778 44.7829C14.4337 44.8543 14.3707 44.9121 14.2957 44.9499C13.7714 45.2108 13.3039 45.5729 12.9201 46.0152C12.5363 46.4575 12.2438 46.9714 12.0594 47.5272L11.8184 48.3272C11.7886 48.4181 11.7307 48.4973 11.6532 48.5534C11.5756 48.6096 11.4824 48.6398 11.3866 48.6398C11.2909 48.6398 11.1976 48.6096 11.1201 48.5534C11.0425 48.4973 10.9847 48.4181 10.9548 48.3272L10.6912 47.5272C10.5068 46.9714 10.2142 46.4575 9.83041 46.0152C9.4466 45.5729 8.9791 45.2108 8.45481 44.9499C8.38036 44.9118 8.31787 44.8538 8.27424 44.7824C8.23061 44.7111 8.20752 44.629 8.20752 44.5454C8.20752 44.4617 8.23061 44.3797 8.27424 44.3083C8.31787 44.2369 8.38036 44.179 8.45481 44.1408C8.9791 43.8799 9.4466 43.5179 9.83041 43.0755C10.2142 42.6332 10.5068 42.1194 10.6912 41.5635L10.9548 40.7635C10.9847 40.6726 11.0425 40.5934 11.1201 40.5373C11.1976 40.4812 11.2909 40.4509 11.3866 40.4509C11.4824 40.4509 11.5756 40.4812 11.6532 40.5373C11.7307 40.5934 11.7886 40.6726 11.8184 40.7635L12.0821 41.5635C12.2665 42.1194 12.559 42.6332 12.9428 43.0755C13.3267 43.5179 13.7942 43.8799 14.3184 44.1408C14.3892 44.1816 14.4476 44.2407 14.4876 44.3119C14.5276 44.3831 14.5477 44.4637 14.5457 44.5454Z" fill="#FFCB00"/>
  <path d="M42.2728 30.0727L38.0683 42.1682C36.4946 43.5352 34.7402 44.6793 32.8546 45.5682L6.41828 36.3954C6.07977 36.2747 5.77473 36.0753 5.5282 35.8138C5.28167 35.5522 5.10068 35.236 5.0001 34.8909L2.32283 25.8C2.18326 25.3432 2.19115 24.8541 2.34536 24.402C2.49958 23.95 2.79229 23.558 3.18192 23.2818L10.9092 17.7909C11.2045 17.5821 11.5454 17.4467 11.9035 17.3961C12.2616 17.3455 12.6267 17.3811 12.9683 17.5L40.8637 27.1773C41.1467 27.2744 41.4077 27.4264 41.6318 27.6246C41.8559 27.8229 42.0386 28.0634 42.1695 28.3324C42.3004 28.6014 42.377 28.8936 42.3947 29.1923C42.4124 29.4909 42.371 29.7901 42.2728 30.0727Z" fill="#0055A1"/>
  <path d="M47.7275 17.2727V32.7273C47.7275 33.33 47.488 33.9081 47.0618 34.3343C46.6356 34.7606 46.0575 35 45.4547 35H10.0275C9.66652 34.9996 9.31087 34.9132 8.98995 34.748C8.66904 34.5828 8.39208 34.3435 8.18201 34.05L2.66383 26.3227C2.38772 25.9369 2.23926 25.4744 2.23926 25C2.23926 24.5256 2.38772 24.0631 2.66383 23.6773L8.18201 15.95C8.39208 15.6565 8.66904 15.4172 8.98995 15.252C9.31087 15.0868 9.66652 15.0004 10.0275 15H45.4547C46.0575 15 46.6356 15.2394 47.0618 15.6657C47.488 16.0919 47.7275 16.67 47.7275 17.2727Z" fill="#FF005B"/>
  <path d="M9.54535 24.9999C9.54535 25.4821 9.35379 25.9446 9.01282 26.2855C8.67184 26.6265 8.20938 26.8181 7.72717 26.8181C7.61274 26.8192 7.4985 26.8085 7.38626 26.7863C6.97022 26.7074 6.59475 26.4858 6.32465 26.1597C6.05454 25.8335 5.90674 25.4234 5.90674 24.9999C5.90674 24.5764 6.05454 24.1663 6.32465 23.8402C6.59475 23.514 6.97022 23.2924 7.38626 23.2135C7.4985 23.1913 7.61274 23.1806 7.72717 23.1817C8.20938 23.1817 8.67184 23.3733 9.01282 23.7143C9.35379 24.0552 9.54535 24.5177 9.54535 24.9999Z" fill="#323FD4"/>
  <path d="M7.38634 23.2135C7.06735 23.2751 6.7707 23.4211 6.52725 23.6362C6.73997 23.7987 6.91623 24.004 7.04463 24.2389C7.17304 24.4738 7.25074 24.733 7.2727 24.9998C7.25074 25.2666 7.17304 25.5258 7.04463 25.7607C6.91623 25.9956 6.73997 26.2009 6.52725 26.3635C5.68973 27.0258 4.71425 27.4918 3.6727 27.7271C2.69319 27.9846 1.68904 28.1369 0.677246 28.1816C0.718155 28.4816 0.76361 28.7816 0.818155 29.0907C1.97264 29.0465 3.11744 28.8618 4.22725 28.5407C5.41953 28.2477 6.51568 27.6511 7.40906 26.8089C7.6593 26.5834 7.85937 26.3079 7.99632 26.0002C8.13327 25.6925 8.20403 25.3594 8.20403 25.0226C8.20403 24.6857 8.13327 24.3527 7.99632 24.0449C7.85937 23.7372 7.6593 23.4617 7.40906 23.2362L7.38634 23.2135ZM4.22725 21.4816C3.11744 21.1605 1.97264 20.9758 0.818155 20.9316C0.76361 21.2316 0.718155 21.5316 0.677246 21.8407C1.68822 21.878 2.69236 22.0228 3.6727 22.2726L4.22725 21.4816Z" fill="#FFA100"/>
  <path d="M15.2275 21.8182H17.7275C17.9686 21.8182 18.1998 21.7224 18.3703 21.5519C18.5408 21.3814 18.6366 21.1502 18.6366 20.9091C18.6366 20.668 18.5408 20.4368 18.3703 20.2663C18.1998 20.0958 17.9686 20 17.7275 20H15.2275C14.4439 20 13.6924 20.3113 13.1383 20.8654C12.5842 21.4195 12.2729 22.171 12.2729 22.9545C12.2729 23.7381 12.5842 24.4896 13.1383 25.0437C13.6924 25.5978 14.4439 25.9091 15.2275 25.9091H15.682C15.9834 25.9091 16.2725 26.0288 16.4856 26.2419C16.6987 26.455 16.8184 26.7441 16.8184 27.0455C16.8184 27.3468 16.6987 27.6359 16.4856 27.849C16.2725 28.0621 15.9834 28.1818 15.682 28.1818H13.182C12.9409 28.1818 12.7097 28.2776 12.5392 28.4481C12.3687 28.6186 12.2729 28.8498 12.2729 29.0909C12.2729 29.332 12.3687 29.5632 12.5392 29.7337C12.7097 29.9042 12.9409 30 13.182 30H15.682C16.4656 30 17.2171 29.6887 17.7712 29.1346C18.3253 28.5805 18.6366 27.829 18.6366 27.0455C18.6366 26.2619 18.3253 25.5104 17.7712 24.9563C17.2171 24.4022 16.4656 24.0909 15.682 24.0909H15.2275C14.9261 24.0909 14.6371 23.9712 14.424 23.7581C14.2109 23.545 14.0911 23.2559 14.0911 22.9545C14.0911 22.6532 14.2109 22.3641 14.424 22.151C14.6371 21.9379 14.9261 21.8182 15.2275 21.8182Z" fill="white"/>
  <path d="M25.3914 20.5818C25.3243 20.4122 25.2079 20.2667 25.0571 20.1642C24.9064 20.0617 24.7282 20.0068 24.5459 20.0068C24.3636 20.0068 24.1855 20.0617 24.0347 20.1642C23.8839 20.2667 23.7675 20.4122 23.7005 20.5818L20.5186 28.7636C20.465 28.8766 20.4352 28.9994 20.4311 29.1244C20.4271 29.2494 20.4489 29.3739 20.4952 29.4901C20.5414 29.6063 20.6112 29.7116 20.7 29.7997C20.7889 29.8877 20.8949 29.9564 21.0116 30.0015C21.1282 30.0467 21.2529 30.0673 21.3779 30.062C21.5028 30.0568 21.6253 30.0258 21.7378 29.9711C21.8502 29.9163 21.9502 29.839 22.0313 29.7438C22.1125 29.6487 22.1732 29.5378 22.2095 29.4181L22.6914 28.1818H26.3959L26.8823 29.4227C26.9489 29.5927 27.0652 29.7387 27.216 29.8416C27.3668 29.9446 27.5451 29.9998 27.7277 30C27.8415 30.0009 27.9542 29.9793 28.0595 29.9363C28.2825 29.8481 28.4616 29.6754 28.5578 29.4557C28.654 29.2361 28.6595 28.9873 28.5732 28.7636L25.3914 20.5818ZM23.4005 26.3636L24.5459 23.4181L25.6914 26.3636H23.4005Z" fill="white"/>
  <path d="M35.9091 28.1818H32.2728V20.9091C32.2728 20.668 32.177 20.4368 32.0065 20.2663C31.836 20.0958 31.6048 20 31.3637 20C31.1226 20 30.8913 20.0958 30.7209 20.2663C30.5504 20.4368 30.4546 20.668 30.4546 20.9091V29.0909C30.4546 29.332 30.5504 29.5632 30.7209 29.7337C30.8913 29.9042 31.1226 30 31.3637 30H35.9091C36.1502 30 36.3815 29.9042 36.552 29.7337C36.7224 29.5632 36.8182 29.332 36.8182 29.0909C36.8182 28.8498 36.7224 28.6186 36.552 28.4481C36.3815 28.2776 36.1502 28.1818 35.9091 28.1818Z" fill="white"/>
  <path d="M44.0913 28.1818H40.4549V25.9091H43.1822C43.4233 25.9091 43.6545 25.8133 43.825 25.6428C43.9955 25.4723 44.0913 25.2411 44.0913 25C44.0913 24.7589 43.9955 24.5277 43.825 24.3572C43.6545 24.1867 43.4233 24.0909 43.1822 24.0909H40.4549V21.8182H43.6367C43.8778 21.8182 44.1091 21.7224 44.2795 21.5519C44.45 21.3814 44.5458 21.1502 44.5458 20.9091C44.5458 20.668 44.45 20.4368 44.2795 20.2663C44.1091 20.0958 43.8778 20 43.6367 20H39.5458C39.3047 20 39.0735 20.0958 38.903 20.2663C38.7325 20.4368 38.6367 20.668 38.6367 20.9091V29.0909C38.6367 29.332 38.7325 29.5632 38.903 29.7337C39.0735 29.9042 39.3047 30 39.5458 30H44.0913C44.3324 30 44.5636 29.9042 44.7341 29.7337C44.9046 29.5632 45.0004 29.332 45.0004 29.0909C45.0004 28.8498 44.9046 28.6186 44.7341 28.4481C44.5636 28.2776 44.3324 28.1818 44.0913 28.1818Z" fill="white"/>
  <defs>
    <radialGradient id="paint0_radial_4616_123649" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.1819 25.7275) rotate(90) scale(22.7273)">
      <stop offset="0.545" stop-color="white"/>
      <stop offset="1" stop-color="#FFFDC0"/>
    </radialGradient>
  </defs>
</svg>
`
