<button
  type="button"
  [class]="classes()"
  [class.w-full]="fluid()"
  [disabled]="disabled()"
  (click)="handleClick($event)"
  [class.!p-0]="isIcon() || noPadding()"
>
  @if (isIcon()) {
    <app-icon [name]="iconName()" [size]="iconSize()" />
  } @else {
    <ng-content />
  }
</button>
