export default `
<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
  <path d="M7.25981 11.8125C7.73848 11.8195 8.21306 11.7236 8.65144 11.5312C9.14942 11.3234 9.57377 10.9713 9.86982 10.5201C10.1659 10.069 10.32 9.53955 10.3125 9C10.3125 7.3125 9.9375 6.9375 9.375 5.8125C8.8125 4.6875 9.375 3.5625 9.375 3.5625C8.92527 3.67649 8.5282 3.94121 8.25 4.3125C8.25 0.375 5.625 0.1875 5.625 0.1875C5.78166 0.622392 5.8294 1.08904 5.76403 1.54665C5.69865 2.00425 5.52217 2.43887 5.25 2.8125C4.3125 4.125 3.375 4.875 3 6.375C3 6.375 2.25 6 2.4375 5.25C2.4375 5.25 1.6875 6.5625 1.6875 8.625C1.6875 10.8866 3.49913 11.55 4.39088 11.7394C4.62804 11.7888 4.86973 11.8133 5.112 11.8125H7.25981Z" fill="#FFCB00"/>
  <path d="M4.65788 11.8125C4.65788 11.8125 2.72363 11.0873 2.72363 8.91113C2.72363 6.4935 4.40138 5.625 4.40138 5.625C4.40138 5.625 3.93263 6.4935 4.4162 6.73519C4.4162 6.73519 5.22526 4.10025 7.40138 3.375C7.40138 3.375 6.64144 4.17431 7.12501 5.625C7.60857 7.07569 9.24676 7.40137 9.27638 9V9.1875C9.29539 9.73045 9.15175 10.2667 8.86388 10.7274C8.49113 11.3081 8.06082 11.8125 7.07569 11.8125H4.65788Z" fill="#FC9E20"/>
  <path d="M4.875 11.8125C4.43114 11.6269 4.05199 11.3144 3.78513 10.9141C3.51827 10.5138 3.37559 10.0436 3.375 9.5625C3.375 7.6875 4.3125 6.9375 4.3125 6.9375C4.3125 6.9375 4.3125 7.6875 4.6875 7.875C4.6875 7.875 5.0625 5.25 6.75 4.6875C6.75 4.6875 6.1875 5.0625 6.5625 6.1875C6.9375 7.3125 8.625 7.6875 8.625 9.5625V9.72581C8.63164 10.1651 8.51252 10.5972 8.28169 10.971C8.12337 11.234 7.89826 11.4504 7.62924 11.5982C7.36021 11.746 7.05686 11.8199 6.75 11.8125H4.875Z" fill="#E40051"/>
  <path d="M4.87496 10.8749C4.83788 10.8749 4.80164 10.8639 4.77081 10.8433C4.73998 10.8227 4.71596 10.7934 4.70177 10.7591C4.68758 10.7249 4.68387 10.6872 4.6911 10.6508C4.69833 10.6145 4.71618 10.5811 4.7424 10.5548L6.9924 8.30483C7.02776 8.27068 7.07512 8.25178 7.12429 8.2522C7.17345 8.25263 7.22047 8.27235 7.25524 8.30711C7.29 8.34188 7.30972 8.38891 7.31015 8.43807C7.31058 8.48723 7.29168 8.53459 7.25752 8.56996L5.00752 10.82C4.97237 10.8551 4.92468 10.8749 4.87496 10.8749Z" fill="#FBFBFB"/>
  <path d="M6.5625 11.0625C6.45125 11.0625 6.34249 11.0295 6.24999 10.9677C6.15749 10.9059 6.08539 10.818 6.04282 10.7153C6.00024 10.6125 5.9891 10.4994 6.01081 10.3903C6.03251 10.2811 6.08609 10.1809 6.16475 10.1023C6.24342 10.0236 6.34365 9.97001 6.45276 9.94831C6.56188 9.9266 6.67498 9.93774 6.77776 9.98032C6.88054 10.0229 6.96839 10.095 7.0302 10.1875C7.09201 10.28 7.125 10.3887 7.125 10.5C7.125 10.6492 7.06574 10.7923 6.96025 10.8977C6.85476 11.0032 6.71168 11.0625 6.5625 11.0625ZM6.5625 10.3125C6.52542 10.3125 6.48917 10.3235 6.45833 10.3441C6.4275 10.3647 6.40346 10.394 6.38927 10.4282C6.37508 10.4625 6.37137 10.5002 6.3786 10.5366C6.38584 10.573 6.4037 10.6064 6.42992 10.6326C6.45614 10.6588 6.48955 10.6767 6.52592 10.6839C6.56229 10.6911 6.59999 10.6874 6.63425 10.6732C6.66851 10.659 6.6978 10.635 6.7184 10.6042C6.739 10.5733 6.75 10.5371 6.75 10.5C6.75 10.4503 6.73025 10.4026 6.69508 10.3674C6.65992 10.3323 6.61223 10.3125 6.5625 10.3125Z" fill="#FBFBFB"/>
  <path d="M5.4375 9.1875C5.32625 9.1875 5.21749 9.15451 5.12499 9.0927C5.03249 9.03089 4.96039 8.94304 4.91782 8.84026C4.87524 8.73748 4.8641 8.62438 4.88581 8.51526C4.90751 8.40615 4.96109 8.30592 5.03975 8.22725C5.11842 8.14859 5.21865 8.09501 5.32776 8.07331C5.43688 8.0516 5.54998 8.06274 5.65276 8.10532C5.75554 8.14789 5.84339 8.21999 5.9052 8.31249C5.96701 8.40499 6 8.51375 6 8.625C6 8.77418 5.94074 8.91726 5.83525 9.02275C5.72976 9.12824 5.58668 9.1875 5.4375 9.1875ZM5.4375 8.4375C5.40042 8.4375 5.36417 8.4485 5.33333 8.4691C5.3025 8.4897 5.27846 8.51899 5.26427 8.55325C5.25008 8.58751 5.24637 8.62521 5.2536 8.66158C5.26084 8.69795 5.2787 8.73136 5.30492 8.75758C5.33114 8.78381 5.36455 8.80166 5.40092 8.8089C5.43729 8.81613 5.47499 8.81242 5.50925 8.79823C5.54351 8.78404 5.5728 8.76 5.5934 8.72917C5.614 8.69834 5.625 8.66208 5.625 8.625C5.625 8.57527 5.60525 8.52758 5.57008 8.49242C5.53492 8.45725 5.48723 8.4375 5.4375 8.4375Z" fill="#FBFBFB"/>
</svg>
`
