export default `
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
  <mask id="mask0_4754_105731" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
    <rect y="0.5" width="16" height="16" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_4754_105731)">
    <path d="M4.42238 7.16667L3.3335 8.17568L8.00016 12.5L12.6668 8.17568L11.5779 7.16667L8.00016 10.464L4.42238 7.16667Z" fill="#0055A1"/>
  </g>
</svg>
`
