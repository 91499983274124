export default `
<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="alarm">
    <mask id="mask0_28_5364" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="20">
      <rect id="Bounding box" x="0.5" width="20" height="20" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_28_5364)">
      <path id="alarm_2" d="M10.5 17.9999C9.52776 17.9999 8.61711 17.8152 7.76804 17.4458C6.91896 17.0763 6.18007 16.5772 5.55138 15.9485C4.92267 15.3198 4.42359 14.5809 4.05415 13.7319C3.6847 12.8828 3.49998 11.9721 3.49998 10.9999C3.49998 10.0277 3.6847 9.11705 4.05415 8.26798C4.42359 7.4189 4.92267 6.68001 5.55138 6.05131C6.18007 5.42261 6.91896 4.92353 7.76804 4.55408C8.61711 4.18464 9.52776 3.99992 10.5 3.99992C11.4722 3.99992 12.3828 4.18464 13.2319 4.55408C14.081 4.92353 14.8199 5.42261 15.4486 6.05131C16.0773 6.68001 16.5764 7.4189 16.9458 8.26798C17.3153 9.11705 17.5 10.0277 17.5 10.9999C17.5 11.9721 17.3153 12.8828 16.9458 13.7319C16.5764 14.5809 16.0773 15.3198 15.4486 15.9485C14.8199 16.5772 14.081 17.0763 13.2319 17.4458C12.3828 17.8152 11.4722 17.9999 10.5 17.9999ZM12.5833 13.8333L13.6458 12.7708L11.25 10.3749V6.99992H9.74998V10.9999L12.5833 13.8333ZM5.43748 2.39575L6.49998 3.45825L2.95831 6.99992L1.89581 5.93742L5.43748 2.39575ZM15.5625 2.39575L19.1041 5.93742L18.0416 6.99992L14.5 3.45825L15.5625 2.39575ZM10.4954 16.4999C12.0262 16.4999 13.3264 15.9667 14.3958 14.9004C15.4653 13.834 16 12.5354 16 11.0045C16 9.47368 15.4668 8.17353 14.4004 7.10409C13.3341 6.03464 12.0355 5.49992 10.5046 5.49992C8.97374 5.49992 7.67359 6.0331 6.60415 7.09946C5.5347 8.16584 4.99998 9.46445 4.99998 10.9953C4.99998 12.5262 5.53316 13.8263 6.59952 14.8958C7.6659 15.9652 8.96451 16.4999 10.4954 16.4999Z" fill="#212529"/>
    </g>
  </g>
</svg>
`
